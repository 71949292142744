@if (isActivate()) {
<p>
  {{ message() }}
</p>
} @else {
<app-register-form
  (registerEvent)="handleFormSubmit($event)"
  [loading]="loading"
/>
}

<p-toast />
