<form (ngSubmit)="onSubmit()" [formGroup]="formService">
  <p-dialog
    header="Crear Servicio"
    [modal]="true"
    [maximizable]="true"
    [(visible)]="visible"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
    [style]="{ width: '50vw' }"
    (onHide)="closeDialog()"
  >
    <span class="block mb-5 p-text-secondary">
      @if (isBudget()) { Nuevo servicio a partir de presupuesto } @else {
      Introduce los datos del nuevo servicio }
    </span>
    <div class="grid">
      <div class="formgrid grid gap-3">
        @if(!isBudget()) {
        <div class="col">
          <app-client-selector
            (clientSelected)="onClientSelected($event)"
            [dialogVisible]="visible"
          />
        </div>
        }
      </div>
    </div>
    <div class="formgrid grid gap-3">
      <div class="col">
        <app-input
          label="Nombre"
          formControlName="name"
          name="name"
          type="name"
        />
      </div>
      <div class="col">
        <app-input
          label="Email"
          formControlName="email"
          name="email"
          type="email"
        />
      </div>
    </div>
    <div class="formgrid grid gap-3">
      <div class="col">
        <app-input
          label="Teléfono"
          formControlName="phone"
          name="phone"
          type="number"
        />
      </div>
      <div class="col">
        <app-input
          label="Teléfono (opcional)"
          formControlName="phone_optional"
          name="phone_optional"
          type="number"
        />
      </div>
    </div>
    <div class="formgrid grid gap-3">
      <div class="col">
        <app-input
          label="Pronvicia"
          formControlName="province"
          name="province"
        />
      </div>
      <div class="col">
        <app-input label="Población" formControlName="city" name="city" />
      </div>
    </div>
    <div class="formgrid grid gap-3">
      <div class="col">
        <app-input label="Dirección" formControlName="address" name="address" />
      </div>
      <div class="col">
        <app-input
          label="Código postal"
          formControlName="postal_code"
          name="postal_code"
          type="number-length"
          [minLength]="5"
          [customErrorMessages]="{
            minlength: 'El Código postal debe tener al menos 5 caracteres'
          }"
        />
      </div>
    </div>

    <div class="formgrid grid gap-3">
      <div class="col">
        <app-input label="NIF/CIF" formControlName="nif_cif" name="nif_cif" />
      </div>
      <div class="col field">
        <label class="font-semibold">Fecha de inicio</label>
        <p-calendar
          formControlName="startAt"
          autoWidth="false"
          [style]="{ width: '100%' }"
          appendTo="body"
          [showTime]="true"
          [showIcon]="true"
          dateFormat="dd/mm/yy"
        />
      </div>
    </div>

    @if(isAdmin()){
    <div class="formgrid grid gap-3">
      <div class="flex justify-content-between align-items-center">
        <div class="col field">
          <label class="font-semibold">Importe</label>
          <p-inputNumber
            autoWidth="false"
            [style]="{ width: '100%' }"
            formControlName="import"
            inputId="minmaxfraction"
            mode="currency"
            [maxFractionDigits]="5"
            currency="EUR"
            locale="es-ES"
          />
        </div>
      </div>
    </div>
    }

    <div class="formgrid grid gap-3">
      <div class="col-6">
        <app-employee-selector
          [dialogVisible]="visible"
          (employeeSelected)="onEmployeeSelected($event)"
        />
      </div>
    </div>

    <div class="formgrid grid gap-3">
      <div class="col-12 field">
        <label class="font-semibold">Descripción</label>
        <textarea
          pInputText
          class="w-full"
          autocomplete="off"
          rows="5"
          formControlName="description"
        ></textarea>
      </div>
    </div>
    @if (isBudget()) {
    <label class="font-semibold">Adjuntos</label>
    <div class="py-3">
      <app-media-viewer [mediaFiles]="mediaFiles()" [actionDisabled]="true" />
    </div>

    }@else {
    <app-upload (filesSelected)="onFilesSelected($event)" />
    }

    <small>* Campo requerido</small>
    <ng-template pTemplate="footer">
      <p-button
        label="Cancelar"
        severity="secondary"
        (onClick)="closeDialog()"
      />
      <p-button label="Guardar" type="submit" />
    </ng-template>
  </p-dialog>
</form>
