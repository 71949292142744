import { Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { LayoutComponent } from './components/layout/layout.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { EmployeesComponent } from './pages/employees/employees.component';
import { authGuard, authRedirectGuard } from './auth/auth.guard';
import { EmployeeDetailsComponent } from './pages/employees/details/employee-details.component';
import { AgentsComponent } from './pages/agents/agents.component';
import { AgentDetailsComponent } from './pages/agents/details/agent-details.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { ClientDetailsComponent } from './pages/clients/details/client-details.component';
import { BudgetsComponent } from './pages/budgets/budgets.component';
import { BudgetDetailsComponent } from './pages/budgets/details/budget-details.component';
import { ServicesComponent } from './pages/services/services.component';
import { ServiceDetailsComponent } from './pages/services/details/service-details.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { RegisterComponent } from './pages/register/register.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [authRedirectGuard],
  },
  {
    path: 'complete-register/:token',
    component: RegisterComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: '',
    canActivate: [authGuard],
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },

      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'categories',
        component: CategoriesComponent,
      },
      {
        path: 'employees',
        component: EmployeesComponent,
      },
      {
        path: 'employees/:id',
        component: EmployeeDetailsComponent,
      },
      {
        path: 'agents',
        component: AgentsComponent,
      },
      {
        path: 'agents/:id',
        component: AgentDetailsComponent,
      },
      {
        path: 'clients',
        component: ClientsComponent,
      },
      {
        path: 'clients/:id',
        component: ClientDetailsComponent,
      },
      {
        path: 'budgets',
        component: BudgetsComponent,
      },
      {
        path: 'budgets/:id',
        component: BudgetDetailsComponent,
      },
      {
        path: 'services',
        component: ServicesComponent,
      },
      {
        path: 'services/:id',
        component: ServiceDetailsComponent,
      },
      {
        path: 'profile',
        component: ProfileComponent,
      },

      {
        path: 'settings',
        component: SettingsComponent,
      },
    ],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
