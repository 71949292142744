import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private readonly document = inject(DOCUMENT);
  private readonly themeKey = 'theme';
  isDarkMode = false;

  initializeTheme() {
    const storedTheme = localStorage.getItem(this.themeKey);
    if (storedTheme == 'light') {
      this.setLightMode();
    } else {
      this.setDarkMode();
    }
  }

  toggleTheme() {
    if (this.isDarkMode) {
      this.setLightMode();
    } else {
      this.setDarkMode();
    }
  }

  private setDarkMode() {
    const linkElement = this.document.getElementById(
      'app-theme'
    ) as HTMLLinkElement;
    linkElement.href = 'theme-dark.css';
    this.isDarkMode = true;
    localStorage.setItem(this.themeKey, 'dark');
  }

  private setLightMode() {
    const linkElement = this.document.getElementById(
      'app-theme'
    ) as HTMLLinkElement;
    linkElement.href = 'theme-light.css';
    this.isDarkMode = false;
    localStorage.setItem(this.themeKey, 'light');
  }
}
