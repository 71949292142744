import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Budget, Budgets, BudgetPayload } from '../interfaces/budget';
import { map, Observable } from 'rxjs';
import { BASEURL } from '../constant/url';

@Injectable({
  providedIn: 'root',
})
export class BudgetService {
  private readonly _http = inject(HttpClient);

  fetchBudgets(page: number, pageSize?: number): Observable<Budgets> {
    return this._http.get<Budgets>(
      `${BASEURL}budgets?pagination[page]=${page}&pagination[pageSize]=${
        pageSize || 10
      }`
    );
  }
  fetchBudget(id: number): Observable<Budget> {
    return this._http
      .get<Budget>(
        `${BASEURL}budgets/${id}?populate[client]=*&populate[media]=*&populate[service]=*&populate[notes][populate]=users_permissions_user&populate[contact_people]=*`
      )
      .pipe(map((response: Budget) => response.data));
  }

  fetchLastBudget(): Observable<Budgets> {
    return this._http.get<Budgets>(
      `${BASEURL}budgets?sort=createdAt:desc&pagination[pageSize]=5`
    );
  }

  addBudget(data: BudgetPayload): Observable<Budget> {
    return this._http.post<Budget>(`${BASEURL}budgets`, { data });
  }

  updateBudget(id: number, data: any): Observable<Budget> {
    return this._http.put<Budget>(`${BASEURL}budgets/${id}`, { data });
  }

  deleteBudget(id: number): Observable<any> {
    return this._http.delete<any>(`${BASEURL}budgets/${id}`);
  }
}
