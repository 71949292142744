import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { UserLogin } from '../interfaces/user_login';
import { BehaviorSubject, map, Observable, switchMap, tap } from 'rxjs';
import { BASEURL, LOGIN } from '../constant/url';
import { Router } from '@angular/router';
import { User } from '../interfaces/user';
import { EMAIL, ID_USER, TOKEN, USERNAME } from '../constant/storage';
import { Roles } from '../utils/roles';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly _http = inject(HttpClient);
  private readonly _router = inject(Router);

  private readonly _role = signal<string | null>(null);
  private readonly _roleSubject = new BehaviorSubject<string | null>(null);

  constructor() {
    if (localStorage.getItem(TOKEN)) {
      this.initializeUserRole();
    }
  }

  private initializeUserRole(): void {
    const token = localStorage.getItem(TOKEN);
    if (token) {
      this.fetchUserRole(token).subscribe((user) => {
        const role = user.role?.id || null;
        this._role.set(role);
        this._roleSubject.next(role);
      });
    }
  }

  login(data: UserLogin): Observable<any> {
    return this._http.post(LOGIN, data);
  }

  doLoginUser(
    token: string,
    username: string,
    email: string,
    role: string,
    idUser: string
  ) {
    localStorage.setItem(TOKEN, token);
    localStorage.setItem(USERNAME, username);
    localStorage.setItem(EMAIL, email);
    localStorage.setItem(ID_USER, idUser);
    this._role.set(role);
    this.initializeUserRole();
    this._router.navigate(['/']);
  }

  fetchUserRole(token: string): Observable<any> {
    const headers = { Authorization: `Bearer ${token}` };
    return this._http.get<User>(BASEURL + 'users/me?populate=role', {
      headers,
    });
  }

  // TODO: borrar
  fetchUser(): Observable<User> {
    return this._http.get<User>(BASEURL + 'users/me?populate=role');
  }

  getRoleObservable(): Observable<string | null> {
    return this._roleSubject.asObservable();
  }

  logout() {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(USERNAME);
    localStorage.removeItem(EMAIL);
    localStorage.removeItem(ID_USER);
    this._role.set(null);
    this._roleSubject.next(null);
    this._router.navigate(['/login']);
  }

  isLoggedIn() {
    return localStorage.getItem(TOKEN);
  }

  chagePassword(
    password: string,
    currentPassword: string,
    passwordConfirmation: string
  ): Observable<any> {
    return this._http.post(BASEURL + 'auth/change-password', {
      password,
      currentPassword,
      passwordConfirmation,
    });
  }

  resetPassword(email: string): Observable<any> {
    return this._http.post(BASEURL + 'auth/forgot-password', { email });
  }
}
