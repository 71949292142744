<form (ngSubmit)="editEmployee()" [formGroup]="formEmployee">
  <app-toolbar
    [createdAt]="(employee().createdAt ? employee().createdAt : '') ?? ''"
    [updatedAt]="(employee().updatedAt ? employee().updatedAt : '') ?? ''"
    (onEdit)="editEmployee()"
    (onDelete)="deleteEmployee()"
  >
  </app-toolbar>

  <div class="grid">
    <p-card class="col-12 md:col-6">
      <span class="flex align-items-center gap-2 pb-3 pt-2">
        <i class="pi pi-info-circle"></i>
        <span class="font-semibold">Información</span>
      </span>
      <p-divider></p-divider>
      <div class="formgrid grid">
        <div class="col">
          <app-input
            name="username"
            formControlName="username"
            label="Nombre*"
            [customErrorMessages]="{
                required: 'Este campo es requerido',
              }"
            type="text-required"
          />
        </div>
        <div class="col">
          <app-input
            name="surname"
            formControlName="surname"
            label="Apellidos"
          />
        </div>
      </div>
      <div class="formgrid grid">
        <div class="col">
          <app-input name="phone" formControlName="phone" label="Teléfono" />
        </div>
        <div class="col">
          <app-input
            name="email"
            formControlName="email"
            label="Email*"
            type="email"
            [formControl]="formUtilities.getControl(formEmployee, 'email')"
            [customErrorMessages]="{
              required: 'Este campo es requerido',
              email: 'Ingresa un email válido'
            }"
          />
        </div>
      </div>
    </p-card>

    <p-card class="col-12 md:col-6">
      <div class="flex justify-content-between">
        <span class="flex align-items-center gap-2">
          <i class="pi pi-tag"></i>
          <span class="font-semibold">Categorías</span>
        </span>
        <p-button
          icon="pi pi-plus"
          label="Añadir"
          size="small"
          (onClick)="addCategory()"
          [text]="true"
        />
      </div>

      @if(formEmployee.get('categories')?.value &&
      formEmployee.get('categories')?.value.length === 0){
      <p-messages
        [(value)]="messages"
        escape="false"
        [closable]="false"
        [enableService]="false"
      />
      } @else{
      <div class="flex flex-column">
        <p-divider />
        @for(category of formEmployee.get('categories')?.value; track $index) {
        <div
          class="flex flex-row align-items-center justify-content-center justify-content-between hover:surface-hover border-round-md p-2"
        >
          <span class="text-sm">{{ category.name }}</span>
          <span>
            <p-button
              icon="pi pi-times"
              [text]="true"
              (click)="removeCategory(category.id)"
            />
          </span>
        </div>
        }
      </div>
      }
    </p-card>
  </div>

  <p-dialog
    header="Categorías"
    [style]="{ width: '25vw' }"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
    [modal]="true"
    [(visible)]="visible"
  >
    <span class="block mb-5 p-text-secondary"
      >Selecciona una o varias categorías para el empleado</span
    >
    <p-multiSelect
      [options]="categories()"
      formControlName="categoriesForm"
      optionLabel="attributes.name"
      optionValue="id"
      placeholder="Seleccione Categorías"
      appendTo="body"
      [selectedItemsLabel]="getSelectedItemsLabel()"
      [style]="{ width: '100%' }"
      [panelStyle]="{ width: '100%' }"
    />

    <ng-template pTemplate="footer">
      <div class="flex justify-content-end gap-2">
        <p-button
          label="Cancelar"
          severity="secondary"
          (onClick)="visible = false"
        />
        <p-button label="Añadir" (onClick)="syncCategories()" />
      </div>
    </ng-template>
  </p-dialog>
</form>
