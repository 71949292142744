<div class="p-4 surface-card shadow-2">
  <p-toolbar styleClass="mb-4 gap-2">
    <ng-template pTemplate="left">
      <p-button
        pRipple
        label="Añadir"
        icon="pi pi-plus"
        class="mr-2"
        (click)="openNew()"
      ></p-button>
      <p-button
        pRipple
        label="Eliminar"
        severity="danger"
        icon="pi pi-trash"
        class="p-button-danger"
        (click)="deleteSelectedRows()"
        [outlined]="true"
        [disabled]="!selected || !selected.length"
      ></p-button>
    </ng-template>

    <ng-template pTemplate="right">
      <p-button
        pRipple
        label="Exportar"
        icon="pi pi-file-export"
        [text]="true"
        (onClick)="table.exportCSV()"
      ></p-button>
    </ng-template>
  </p-toolbar>

  <p-table
    #table
    [loading]="loading"
    [loadingIcon]="'pi pi-spin pi-spinner '"
    [columns]="columns"
    [value]="data"
    [globalFilterFields]="filters"
    [(selection)]="selected"
    [exportHeader]="'customExportHeader'"
    (onPage)="onPage($event)"
    rowHover="true"
    responsiveLayout="stack"
    [breakpoint]="'960px'"
    styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
    [tableStyle]="{ 'min-width': '50rem' }"
  >
    <ng-template pTemplate="caption">
      <p-iconField iconPosition="left" class="ml-auto">
        <p-inputIcon>
          <i class="pi pi-search"></i>
        </p-inputIcon>
        <input
          pInputText
          id="global_filter"
          class="p-input text-sm"
          type="text"
          (input)="onGlobalFilter($event)"
          placeholder="Buscar"
        />
      </p-iconField>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 3rem">
          <p-tableHeaderCheckbox />
        </th>
        @for (column of columns; track $index) {
        <th [pSortableColumn]="column.sortable ? column.field : null">
          {{ column.header }}
          @if (column.sortable) {
          <p-sortIcon field="code" />
          }
        </th>
        }
        <th></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td>
          <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
        </td>
        @for (column of columns; track $index) { @if
        (isCategoriesColumn(column)) {
        <td>
          @for (row of rowData[column.field]; track $index) {
          <app-chip class="mr-2" [status]="row" [small]="true"> </app-chip>
          }
        </td>

        } @else if (isStatusColumn(column)) {
        <td>
          <app-chip [status]="rowData[column.field]" [small]="true"> </app-chip>
        </td>
        } @else {
        <td>
          {{ rowData[column.field] }}
        </td>
        } }
        <td class="text-center">
          <i
            class="pi pi-pencil"
            style="cursor: pointer"
            (click)="activeItem = rowData; onEdit(rowData)"
          ></i>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="summary">
      <div class="flex justify-content-end align-items-center">
        <span class="text-sm text-color-secondary">
          Total: {{ totalRecords }}
        </span>
      </div>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="4">Sin registros</td>
      </tr>
    </ng-template>
  </p-table>
  <p-paginator
    [rowsPerPageOptions]="[10, 20, 50]"
    [rows]="10"
    [showJumpToPageInput]="true"
    [totalRecords]="totalRecords"
    (onPageChange)="onPage($event)"
  ></p-paginator>
  <p-menu #menu [popup]="true" [model]="menuItems"></p-menu>
</div>
