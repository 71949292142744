<form (ngSubmit)="onSubmit()" [formGroup]="formAgent">
  <p-dialog
    header="Añadir Agente"
    [modal]="true"
    [maximizable]="true"
    [(visible)]="visible"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
    [style]="{ width: '50vw' }"
    (onHide)="closeDialog()"
  >
    <span class="block mb-5 p-text-secondary"
      >Introduce los datos del nuevo agente</span
    >

    <div class="formgrid grid gap-3">
      <div class="col">
        <app-input
          label="Nombre*"
          [formControl]="formUtilities.getControl(formAgent, 'name')"
          name="name"
          type="text-required"
          [customErrorMessages]="{
          required: 'Este campo es requerido',
        }"
        />
      </div>
      <div class="col">
        <app-input label="Apellidos" formControlName="surname" name="surname" />
      </div>
    </div>
    <div class="formgrid grid gap-3">
      <div class="col">
        <app-input
          label="Email"
          formControlName="email"
          name="email"
          type="email"
        />
      </div>
      <div class="col">
        <app-input
          label="Teléfono"
          formControlName="phone"
          name="phone"
          type="number"
        />
      </div>
    </div>
    <div class="formgrid grid gap-3">
      <div class="col">
        <app-input
          label="Teléfono (opcional)"
          formControlName="phone_optional"
          name="phone_optional"
          type="number"
        />
      </div>
      <div class="col">
        <app-input
          label="Pronvicia"
          formControlName="province"
          name="province"
        />
      </div>
    </div>
    <!-- Falta localidad -->
    <div class="formgrid grid gap-3">
      <div class="col">
        <app-input label="Población" formControlName="city" name="city" />
      </div>
      <div class="col">
        <app-input label="Dirección" formControlName="address" name="address" />
      </div>
    </div>
    <div class="formgrid grid gap-3">
      <div class="col">
        <app-input
          label="Código postal"
          formControlName="postal_code"
          name="postal_code"
          type="number-length"
          [minLength]="5"
          [customErrorMessages]="{
            minlength: 'El Código postal debe tener al menos 5 caracteres'
          }"
        />
      </div>
      <div class="col">
        <app-input label="NIF/CIF" formControlName="nif_cif" name="nif_cif" />
      </div>
    </div>

    <small>*Campo requerido</small>

    <ng-template pTemplate="footer">
      <p-button
        label="Cancelar"
        severity="secondary"
        (onClick)="closeDialog()"
      />
      <p-button label="Aceptar" type="submit" />
    </ng-template>
  </p-dialog>
</form>
