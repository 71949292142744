import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { Category } from '../../../interfaces/category';
import { CategoriesService } from '../../../services/categories.service';
import { UserCreate } from '../../../interfaces/user';
import { Roles } from '../../../utils/roles';
import { FormUtilitiesService } from '../../../services/from.utilitilies.service';
import { InputComponent } from '../../../components/input/input.component';

@Component({
  selector: 'app-add-employee',
  standalone: true,
  imports: [
    DialogModule,
    ReactiveFormsModule,
    ButtonModule,
    MultiSelectModule,
    InputComponent,
  ],
  templateUrl: './add-employee.component.html',
})
export class AddEmployeeComponent implements OnInit {
  private _visible = false;

  @Input()
  get visible(): boolean {
    return this._visible;
  }

  set visible(value: boolean) {
    this._visible = value;
    if (this._visible && !this.categories().length) {
      this.fetchCategories();
    }
  }
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() save = new EventEmitter<UserCreate>();

  private readonly _categoriesService = inject(CategoriesService);
  private readonly _fb = inject(FormBuilder);
  protected formUtilities = inject(FormUtilitiesService);

  categories = signal<Category[]>([]);
  formEmployee!: FormGroup;

  ngOnInit(): void {
    this.loadForm();
  }

  private loadForm(): void {
    this.formEmployee = this._fb.group({
      username: [''],
      surname: [''],
      email: [''],
      phone: [''],
      categories: [[]],
    });
  }

  private fetchCategories() {
    this._categoriesService.fetchCategories(1, 100).subscribe({
      next: (categories) => this.categories.set(categories?.data || []),
      error: (err) => console.error('Error loading categories', err),
    });
  }

  closeDialog(): void {
    this.visible = false;
    this.visibleChange.emit(this.visible);
    this.formEmployee.reset();
  }

  onSubmit(): void {
    if (this.formEmployee.valid) {
      const formValue = this.formEmployee.value;

      const userCreate: UserCreate = {
        ...formValue,
        role: Roles.MOBILE,
      };
      this.save.emit(userCreate);
      this.closeDialog();
    }
  }
}
