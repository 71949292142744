<div class="flex justify-content-start">
  @if (statusHistories.length > 0) {
  <p-timeline [value]="processedHistories">
    <ng-template pTemplate="marker" let-event>
      <i [class]="getIconSeverity(event.status) + ' primary'"></i>
    </ng-template>

    <ng-template pTemplate="content" let-event>
      {{ getStatus(event.status) }} <br />
      <small class="p-text-secondary"> {{ event.user }}</small>
    </ng-template>
    <ng-template pTemplate="opposite" let-event>
      <small class="p-text-secondary">
        {{ event.date | date : "dd/MM/yyyy HH:mm" }}</small
      >
    </ng-template>
  </p-timeline>
  } @else {
  <div class="grid col-12">
    <div class="col-12">
      <p-messages
        [(value)]="messages"
        escape="false"
        [closable]="false"
        [enableService]="false"
      />
    </div>
  </div>

  }
</div>
