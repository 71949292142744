import { Component, inject, signal } from '@angular/core';
import { LoginFormComponent } from '../../components/login-form/login-form.component';
import { UserLogin } from '../../interfaces/user_login';
import { AuthService } from '../../services/auth.service';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { Roles } from '../../utils/roles';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [LoginFormComponent, ToastModule],
  templateUrl: './login.component.html',
  providers: [MessageService],
})
export class LoginComponent {
  private readonly _authService = inject(AuthService);
  private readonly _messageService = inject(MessageService);
  private readonly _jwt = signal<string | null>(null);

  protected loading = false;

  protected handleFormSubmit(userLogin: UserLogin) {
    this.loading = true;

    this._authService.login(userLogin).subscribe({
      next: (value) => {
        this._jwt.set(value.jwt);
        this._authService.fetchUserRole(this._jwt() ?? '').subscribe({
          next: (value) => {
            if (value.role.id == Roles.ADMIN || value.role.id == Roles.WEB) {
              this._authService.doLoginUser(
                this._jwt()!,
                value.username,
                value.email,
                value.role.id,
                value.id
              );
            } else {
              this._messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'No autorizado',
              });
            }
          },

          error: (error) => {
            this._messageService.add({
              severity: 'error',
              summary: 'Error',
              detail:
                error?.error?.error?.message || 'Credenciales incorrectas',
            });
          },
        });
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this._messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error?.error?.error?.message || 'Credenciales incorrectas',
        });
      },
    });
  }

  protected resetPassword(data: any) {
    this.loading = true;
    this._authService.resetPassword(data.identifier).subscribe({
      next: (value) => {
        this._messageService.add({
          severity: 'success',
          summary: 'Info',
          detail:
            'Se ha enviado un email a ' +
            data.identifier +
            ' para restablecer la contraseña',
        });
      },
      error: (error) => {
        this._messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error?.error?.error?.message || 'Ha ocurrido un error',
        });
      },
    });
    this.loading = false;
  }
}
