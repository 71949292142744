import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { User, Users } from '../../interfaces/user';
import { Message } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { AccordionModule } from 'primeng/accordion';
import { ChipComponent } from '../chip/chip.component';
import { EmployeeSelectorComponent } from '../employee-selector/employee-selector.component';
import { DialogModule } from 'primeng/dialog';

@Component({
  selector: 'app-employee-list',
  standalone: true,
  imports: [
    CardModule,
    ButtonModule,
    MessagesModule,
    AccordionModule,
    ChipComponent,
    EmployeeSelectorComponent,
    DialogModule,
  ],
  templateUrl: './employee-list.component.html',
})
export class EmployeeListComponent implements OnInit {
  @Input() employees: Users = {} as Users;
  @Output() employeeSelected = new EventEmitter<User[]>();

  employeesIds: User[] = [];
  protected messages = signal<Message[]>([]);
  protected visible = false;

  ngOnInit(): void {
    this.initialMessage();
    this.employeesIds = this.employees.data;
  }

  private initialMessage() {
    if (!this.employees || this.employees.data.length === 0) {
      this.messages.set([
        {
          severity: 'info',
          summary: 'Sin Empleados.',
        },
      ]);
    }
  }

  selectEmployees() {
    this.visible = true;
  }

  onEmployeeSelected(employeesIds: User[]): void {
    this.employeesIds = employeesIds;
  }

  addEmployees() {
    this.visible = false;
    this.employeeSelected.emit(this.employeesIds);
  }
}
