import { Component, inject, OnInit, signal } from '@angular/core';
import { CategoriesService } from '../../services/categories.service';
import { Categories, CategoryTable } from '../../interfaces/category';
import {
  Column,
  DataTableComponent,
} from '../../components/data-table/data-table.component';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ConfirmationService, MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { HeaderService } from '../../services/header.service';
import { CrudOperations } from '../../interfaces/crud_table';
import { AddEditCategoryComponent } from './add-edit-category/add-edit-category.component';

@Component({
  selector: 'app-categories',
  standalone: true,
  imports: [
    DataTableComponent,
    ButtonModule,
    InputTextModule,
    AddEditCategoryComponent,
  ],
  templateUrl: './categories.component.html',
})
export class CategoriesComponent implements OnInit, CrudOperations<any> {
  private readonly _headerService = inject(HeaderService);
  private readonly _categoryService = inject(CategoriesService);
  private readonly _messageService = inject(MessageService);
  private readonly _confirmDialog = inject(ConfirmationService);

  protected categories = signal<Categories>({});
  protected categoriesTable = signal<CategoryTable[]>([]);
  protected totalRecords = signal<number>(0);
  protected loading = signal<boolean>(false);

  dialogVisible = false;
  dialogTitle = '';
  dialogSubtitle = '';
  isEditMode = false;
  currentCategory: any = null;

  protected columns: Column = [
    { field: 'id', header: 'ID', sortable: true },
    { field: 'name', header: 'Nombre', sortable: true },
  ];

  protected filters: string[] = ['id', 'name'];

  ngOnInit(): void {
    this._headerService.setHeader('Categorías', false);
    this.loadCategories();
  }

  private loadCategories(page: number = 1, pageSize: number = 10): void {
    this.loading.set(true),
      this._categoryService.fetchCategories(page, pageSize).subscribe({
        next: (categories: Categories) => {
          this.categories.set(categories);
          this.totalRecords.set(categories.meta?.pagination.total || 0);

          this.categoriesTable.set(
            categories?.data
              ?.map((category) => ({
                id: category.id,
                name: category.attributes.name,
              }))
              .sort((a, b) => b.id - a.id) || []
          );
        },
        error: () => {
          this._messageService.add({
            severity: 'error',
            summary: 'Categorías no encontradas',
            detail: 'Ha ocurrido un error al obtener las categorías',
          });
        },
        complete: () => {
          this.loading.set(false);
        },
      });
  }

  onPage(event: { page: number; rows: number }): void {
    const page = event.page + 1;
    const pageSize = event.rows;
    this.loadCategories(page, pageSize);
  }

  onAdd(): void {
    this.dialogTitle = 'Añadir Categoría';
    this.dialogSubtitle = 'Ingrese el nombre de la nueva categoría';
    this.isEditMode = false;
    this.dialogVisible = true;
  }

  onEdit(item: any): void {
    this.dialogTitle = 'Editar Categoría';
    this.dialogSubtitle = 'Introduzca el nuevo nombre de la categoría';
    this.isEditMode = true;
    this.currentCategory = item;
    this.dialogVisible = true;
  }
  onSave(categoryData: any): void {
    if (this.isEditMode && this.currentCategory) {
      this.updateCategory(this.currentCategory.id, categoryData.name);
    } else {
      this.addCategory(categoryData.name);
    }
  }

  onRemoveSelected(item: any) {
    this.removeCategories(item.map((item: { id: any }) => item.id));
  }

  private addCategory(category: string) {
    const categoryData = {
      data: {
        name: category,
      },
    };
    this._categoryService.addCategory(categoryData).subscribe({
      next: () => {
        this.ngOnInit();
        this._messageService.add({
          severity: 'success',
          summary: 'Categoría agregada',
          detail: 'Categoría agregada correctamente',
        });
      },
      error: (err) => {
        console.error(err);
        this._messageService.add({
          severity: 'error',
          summary: 'Categoría no agregada',
          detail: 'Categoría no agregada correctamente',
        });
      },
    });
  }

  private updateCategory(id: number, category: string) {
    const categoryData = {
      data: {
        name: category,
      },
    };
    this._categoryService.updateCategory(id, categoryData).subscribe({
      next: () => {
        this.ngOnInit();
        this._messageService.add({
          severity: 'success',
          summary: 'Categoría actualizada',
          detail: 'Categoría actualizada correctamente',
        });
      },
      error: (err) => {
        console.error(err);
        this._messageService.add({
          severity: 'error',
          summary: 'Categoría no actualizada',
          detail: 'Categoría no actualizada correctamente',
        });
      },
    });
  }

  private removeCategories(ids: number[]) {
    this._confirmDialog.confirm({
      message: '¿Estás seguro de eliminar las categorías seleccionadas?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sí',
      rejectLabel: 'No',

      accept: () => {
        const deleteRequests = ids.map((id) =>
          this._categoryService.deleteCategory(id)
        );
        forkJoin(deleteRequests).subscribe({
          next: () => {
            this.ngOnInit();
            this._messageService.add({
              severity: 'success',
              summary: 'Categorías eliminadas',
              detail: 'Categorías eliminadas correctamente',
            });
          },
          error: (err) => {
            console.error(err);
            this._messageService.add({
              severity: 'error',
              summary: 'Categorías no eliminadas',
              detail: 'Ha ocurrido un error al eliminar las categorías',
            });
          },
        });
      },
    });
  }
}
