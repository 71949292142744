<div
  class="surface-card shadow-2 border-round p-4 flex flex-column cursor-pointer"
>
  <span class="flex align-items-center">
    <i class="pi pi-hammer pr-2 text-blue-500"></i>
    <span class="block text-500 font-medium">Últimos Servicios</span>
  </span>
  <p-divider></p-divider>
  @for (service of services().data; track $index) {
  <div
    class="flex justify-content-between align-items-center hover:surface-hover p-4"
    (click)="goToServices(service.id)"
  >
    <span class="text-xs font-semibold text-primary">
      {{ service.attributes.createdAt | date : "dd/MM/yyyy" }}
    </span>
    <span class="text-sm">
      {{ service?.attributes?.client?.data?.attributes?.name ?? "Sin cliente" }}
    </span>
    <i class="pi pi-chevron-right text-xs"></i>
  </div>

  }
</div>
