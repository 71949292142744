import { Component, inject, OnInit, signal } from '@angular/core';
import { CrudOperations } from '../../interfaces/crud_table';
import { HeaderService } from '../../services/header.service';
import { ClientService } from '../../services/client.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { Clients, ClientTable } from '../../interfaces/client';
import {
  Column,
  DataTableComponent,
} from '../../components/data-table/data-table.component';
import { ButtonModule } from 'primeng/button';
import { AddClientComponent } from './add-client/add-client.component';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-clients',
  standalone: true,
  imports: [DataTableComponent, ButtonModule, AddClientComponent],
  templateUrl: './clients.component.html',
})
export class ClientsComponent implements OnInit, CrudOperations<any> {
  private readonly _headerService = inject(HeaderService);
  private readonly _clientService = inject(ClientService);
  private readonly _messageService = inject(MessageService);
  private readonly _confirmDialog = inject(ConfirmationService);
  private readonly _router = inject(Router);
  protected clients = signal<Clients>({} as Clients);
  protected clientsTable = signal<ClientTable[]>([]);
  protected totalRecords = signal<number>(0);
  protected dialogVisible = false;

  protected columns: Column = [
    { field: 'id', header: 'ID' },
    { field: 'name', header: 'Nombre', sortable: true },
    { field: 'address', header: 'Dirección', sortable: true },
    { field: 'email', header: 'Email' },
    { field: 'phone', header: 'Telefóno' },
  ];

  protected filters: string[] = ['id', 'name', 'address', 'email', 'phone'];

  ngOnInit(): void {
    this._headerService.setHeader('Clientes', false);
    this.loadClients();
  }

  private loadClients(page: number = 1, pageSize: number = 10): void {
    this._clientService.fetchClients(page, pageSize).subscribe({
      next: (clients) => {
        this.clients.set(clients);
        this.totalRecords.set(clients.meta.pagination.total || 0);

        this.clientsTable.set(
          this.clients()
            .data.map((client) => ({
              id: client.id,
              name: client.attributes.name,
              address: client.attributes.address,
              email: client.attributes.email,
              phone: client.attributes.phone,
            }))
            .sort((a, b) => b.id - a.id)
        );
      },
      error: () => {
        this._messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error al obtener los clientes',
        });
      },
    });
  }

  onAdd(): void {
    this.dialogVisible = true;
  }

  onEdit(item: any): void {
    this._router.navigate(['/clients', item.id]);
  }

  onRemoveSelected(items: any[]): void {
    this.removeAgents(items.map((item) => item.id));
  }

  onSave(clientData: any): void {
    this.dialogVisible = false;

    this._clientService.addClient(clientData).subscribe({
      next: () => {
        this.ngOnInit();
        this._messageService.add({
          severity: 'success',
          summary: 'Contacto creado',
          detail: 'Contacto creado correctamente',
        });
      },
      error: () => {
        this._messageService.add({
          severity: 'error',
          summary: 'Contacto no creado',
          detail: 'Ha ocurrido un error al crear el contacto',
        });
      },
    });
  }

  onPage(event: { page: number; rows: number }): void {
    const page = event.page + 1;
    const pageSize = event.rows;
    this.loadClients(page, pageSize);
  }

  private removeAgents(ids: number[]): void {
    this._confirmDialog.confirm({
      message: '¿Deseas eliminar los clientes seleccionados?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const deleteRequest = ids.map((id) =>
          this._clientService.deleteClient(id)
        );
        forkJoin(deleteRequest).subscribe({
          next: () => {
            this.ngOnInit();
            this._messageService.add({
              severity: 'success',
              summary: 'Agentes eliminados',
              detail: 'Agentes eliminados correctamente',
            });
          },
          error: () => {
            this._messageService.add({
              severity: 'error',
              summary: 'Agentes no eliminados',
              detail: 'Ha ocurrido un error al eliminar los agentes',
            });
          },
        });
      },
    });
  }
}
