import { Component, inject, OnInit, signal } from '@angular/core';
import { HeaderService } from '../../../services/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../../../services/user.service';
import { User } from '../../../interfaces/user';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CategoriesService } from '../../../services/categories.service';
import { Category } from '../../../interfaces/category';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToolbarComponent } from '../../../components/toolbar/toolbar.component';
import { InputComponent } from '../../../components/input/input.component';
import { FormUtilitiesService } from '../../../services/from.utilitilies.service';
import { MessagesModule } from 'primeng/messages';

@Component({
  selector: 'app-employee-details',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    InputTextModule,
    DividerModule,
    ButtonModule,
    ReactiveFormsModule,
    DialogModule,
    MultiSelectModule,
    ToolbarComponent,
    InputComponent,
    MessagesModule,
  ],
  templateUrl: './employee-details.component.html',
})
export class EmployeeDetailsComponent implements OnInit {
  private readonly _headerService = inject(HeaderService);
  private readonly _activeRoute = inject(ActivatedRoute);
  private readonly _userService = inject(UsersService);
  private readonly _categoryService = inject(CategoriesService);
  private readonly _messageService = inject(MessageService);
  private readonly _confirmDialog = inject(ConfirmationService);
  private readonly _router = inject(Router);
  private _fb = inject(FormBuilder);
  protected formUtilities = inject(FormUtilitiesService);

  protected messages = signal<Message[]>([]);
  private readonly _id = signal<number>(0);
  protected employee = signal<User>({} as User);
  protected categories = signal<Category[]>([]);
  protected formEmployee!: FormGroup;
  protected visible = false;

  ngOnInit(): void {
    this.getEmployee();
    this.loadForm();
    this.initialMessage();
  }

  private initialMessage() {
    this.messages.set([
      {
        severity: 'info',
        summary: 'Empleado sin categoría.',
        detail: 'Para asignar una categoría pulse en el botón de <u>Añadir</u>',
      },
    ]);
  }

  private loadForm() {
    this.formEmployee = this._fb.group({
      username: [''],
      surname: [''],
      email: [''],
      phone: [''],
      password: [''],
      categories: [[]],
      categoriesForm: [[]],
    });
  }

  private getEmployee() {
    this._activeRoute.params.subscribe((params) => {
      this._id.set(params['id']);
      this._userService.getUser(this._id()).subscribe({
        next: (user) => {
          this.employee.set(user);
          this.updateForm();

          this._headerService.setHeader('Empleado #' + this._id(), true);
        },
        error: () => {
          this._messageService.add({
            severity: 'error',
            summary: 'Empleado no encontrado',
            detail: 'Ha ocurrido un error al obtener el empleado',
          });
        },
      });
    });
  }

  private updateForm() {
    const employeeData = this.employee();
    this.formEmployee.patchValue({
      username: this.employee().username,
      surname: this.employee().surname,
      email: this.employee().email,
      phone: this.employee().phone,
      categories: this.employee().categories,
      categoriesForm: employeeData?.categories?.map((cat) => cat.id),
    });
  }

  editEmployee() {
    const employeForm = this.formEmployee.value;
    const { password, categoriesForm, ...employeEdit } = employeForm;

    const updatedEmployee = {
      ...employeEdit,
      role: '1',
      categories: employeForm.categories.map(
        (category: { id: number }) => category.id
      ),
    };

    this._userService.updateUser(this._id(), updatedEmployee).subscribe({
      next: () => {
        this._messageService.add({
          severity: 'success',
          summary: 'Empleado actualizados',
          detail: 'Empleado actualizado correctamente',
        });
      },
      error: () => {
        this._messageService.add({
          severity: 'error',
          summary: 'Empleado no actualizado',
          detail: 'Ha ocurrido un error al actualizar el empleado',
        });
      },
    });
  }

  removeCategory(categoryId: number) {
    this._confirmDialog.confirm({
      message: '¿Seguro que quieres eliminar esta categoría?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const currentCategories = this.formEmployee.get('categories')?.value;
        const updatedCategories = currentCategories.filter(
          (category: { id: number }) => category.id !== categoryId
        );
        this.formEmployee.get('categories')?.setValue(updatedCategories);
        this.editEmployee();
      },
    });
  }

  addCategory() {
    this.visible = true;
    const currentCategories =
      this.formEmployee
        .get('categories')
        ?.value.map((cat: { id: number }) => cat.id) || [];
    this.formEmployee.patchValue({
      categoriesForm: currentCategories,
    });
    this._categoryService.fetchCategories(1, 100).subscribe({
      next: (categories) => {
        this.categories.set(categories.data || []);
      },
      error: () => {
        this._messageService.add({
          severity: 'error',
          summary: 'Categorías no encontradas',
          detail: 'Ha ocurrido un error al obtener las categorías',
        });
      },
    });
  }

  getSelectedItemsLabel(): string {
    const selectedCategories = this.formEmployee.get('categories')?.value || [];
    const selectedCount = selectedCategories.length;
    return selectedCount === 0
      ? 'Seleccione Categorías'
      : `${selectedCount} categorías seleccionadas`;
  }

  syncCategories() {
    const selectedCategoryIds =
      this.formEmployee.get('categoriesForm')?.value || [];
    const selectedCategories = selectedCategoryIds.map((id: number) => {
      const category = this.categories().find((cat) => cat.id === id);
      return { id: category?.id, name: category?.attributes.name };
    });
    this.formEmployee.patchValue({
      categories: selectedCategories,
    });
    this.visible = false;
    this.editEmployee();
  }

  deleteEmployee() {
    this._confirmDialog.confirm({
      message: '¿Esta seguro de eliminar este empleado?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
        this._confirmDialog.close();
        this._userService.deleteUser(this._id()).subscribe({
          next: () => {
            this._messageService.add({
              severity: 'success',
              summary: 'Empleado eliminados',
              detail: 'Empleado eliminado correctamente',
            });
            this._router.navigate(['/employees']);
          },
          error: () => {
            this._messageService.add({
              severity: 'error',
              summary: 'Empleado no eliminados',
              detail: 'Ha ocurrido un error al eliminar el empleado',
            });
          },
        });
      },
    });
  }
}
