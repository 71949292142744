import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { MessagesModule } from 'primeng/messages';
import { MultiSelect, MultiSelectModule } from 'primeng/multiselect';
import { User } from '../../interfaces/user';
import { UsersService } from '../../services/user.service';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Category } from '../../interfaces/category';

@Component({
  selector: 'app-employee-selector',
  standalone: true,
  imports: [
    ButtonModule,
    MessagesModule,
    DialogModule,
    MultiSelectModule,
    ReactiveFormsModule,
  ],
  templateUrl: './employee-selector.component.html',
})
export class EmployeeSelectorComponent implements OnInit, OnChanges {
  @Input() dialogVisible = false;
  @Input() employeeIds: User[] = [];
  @Output() employeeSelected = new EventEmitter<User[]>();
  @ViewChild(MultiSelect) multiSelect!: MultiSelect;

  private readonly _userService = inject(UsersService);
  private readonly _messageService = inject(MessageService);

  protected employees = signal<User[]>([]);
  protected groupedEmployees = signal<any[]>([]);
  categories = signal<Category[]>([]);
  protected formEmployees!: FormGroup;
  private _fb = inject(FormBuilder);

  private usersLoaded = false;

  ngOnInit(): void {
    this.loadForm();
    if (this.dialogVisible) {
      this.loadUsersIfNeeded();
    }
  }

  ngOnChanges(): void {
    if (!this.dialogVisible) {
      if (this.multiSelect) {
        setTimeout(() => {
          this.multiSelect.clear(new Event('clear'));
        });
      }
    }
    if (this.dialogVisible && !this.usersLoaded) {
      this.loadUsersIfNeeded();
    }
  }

  private loadForm() {
    this.formEmployees = this._fb.group({
      employes: [[]],
    });
    this.formEmployees.valueChanges.subscribe((value) => {
      this.employeeSelected.emit(value.employes);
    });
  }

  private loadUsersIfNeeded() {
    if (!this.usersLoaded) {
      this._userService.fetchUsers(1, 100).subscribe({
        next: (users) => {
          this.employees.set(users.data);
          this.groupEmployeesByCategory(users.data);
          this.formEmployees
            .get('employes')
            ?.setValue(this.employeeIds.map((id) => id.id));
          this.usersLoaded = true;
        },
        error: () => {
          this._messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'No se pudieron cargar los empleados',
          });
        },
      });
    }
  }

  private groupEmployeesByCategory(employees: any[]) {
    const categoryMap: { [key: string]: any[] } = {};

    employees.forEach((employee) => {
      if (employee.categories) {
        employee.categories?.forEach((category: { name: string }) => {
          if (!categoryMap[category.name]) {
            categoryMap[category.name] = [];
          }
          categoryMap[category.name].push({
            label: employee.username,
            value: employee.id,
          });
        });
      } else {
        if (!categoryMap['Sin Categoría']) {
          categoryMap['Sin Categoría'] = [];
        }
        categoryMap['Sin Categoría'].push({
          label: employee.username,
          value: employee.id,
        });
      }
    });
    const grouped = Object.keys(categoryMap).map((category) => ({
      label: category,
      items: categoryMap[category],
    }));

    this.groupedEmployees.set(grouped);
  }
}
