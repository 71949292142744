import { Component, inject, OnInit, signal } from '@angular/core';
import { RegisterFormComponent } from '../../components/register-form/register-form.component';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ActivatedRoute } from '@angular/router';
import { UserRegister } from '../../interfaces/user_register';
import { UsersService } from '../../services/user.service';

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [RegisterFormComponent, ToastModule],
  templateUrl: './register.component.html',
  providers: [MessageService],
})
export class RegisterComponent implements OnInit {
  private readonly _token = signal<string>('');

  private readonly _activeRoute = inject(ActivatedRoute);
  private readonly _registerService = inject(UsersService);
  private readonly _messageService = inject(MessageService);
  protected loading: boolean = false;
  protected isActivate = signal<boolean>(false);
  protected message = signal<string>('');

  ngOnInit(): void {
    this._activeRoute.params.subscribe((params) => {
      const token = params['token'];
      if (token) {
        this._token.set(token);
      }
    });
  }

  protected handleFormSubmit(userRegister: UserRegister) {
    this.loading = true;
    if (userRegister.password !== userRegister.confirmPassword) {
      this._messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Las contraseñas no coinciden',
      });
      this.loading = false;
      return;
    } else {
      this._registerService
        .activationUser(this._token(), userRegister.password)
        .subscribe({
          next: () => {
            this.message.set(
              'Registro completado con éxito, Puede cerrar esta página.'
            );
            this.isActivate.set(true);
          },
          error: (err) => {
            this._messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: err.error.error.message,
            });
          },
        });
      this.loading = false;
    }
  }
}
