<div
  class="flex justify-content-center align-items-center min-h-screen background"
>
  <p-card
    [style]="{ width: '25rem' }"
    header=" {{ isResetPassword ? '🔑 Nueva contraseña' : '🔐 Registro' }}"
    subheader="{{
      isResetPassword
        ? 'Ingresa tu nueva contraseña'
        : 'Ingresa tu contraseña para completar el registro'
    }}"
  >
    <form [formGroup]="formRegister" (ngSubmit)="onSubmit()">
      <div class="flex flex-column gap-3 p-4">
        <app-input
          label="Contraseña"
          icon="pi pi-lock"
          [formControl]="formUtilities.getControl(formRegister, 'password')"
          type="password"
          name="password"
          [customErrorMessages]="{
            required: 'La contraseña es obligatoria',
            minlength: 'La contrasñea debe tener al menos 6 caracteres'
          }"
        />
        <app-input
          label="Repite Contraseña"
          icon="pi pi-lock"
          [formControl]="
            formUtilities.getControl(formRegister, 'confirmPassword')
          "
          type="password"
          name="confirmPassword"
          [customErrorMessages]="{
            required: 'La contraseña es obligatoria',
            minlength: 'La contrasñea debe tener al menos 6 caracteres'
          }"
        />
        <div class="pt-4">
          <button
            pButton
            pRipple
            label=" {{ isResetPassword ? 'Guardar' : 'Registrar' }}"
            class="p-3 w-full"
            [loading]="loading"
          ></button>
        </div>
      </div>
    </form>
  </p-card>
</div>
