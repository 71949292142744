import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { UserLogin } from '../../interfaces/user_login';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InputComponent } from '../input/input.component';
import { FormUtilitiesService } from '../../services/from.utilitilies.service';

@Component({
  selector: 'app-login-form',
  standalone: true,

  imports: [CardModule, ButtonModule, ReactiveFormsModule, InputComponent],
  templateUrl: './login-form.component.html',
})
export class LoginFormComponent implements OnInit {
  @Input() loading = false;
  @Output() loginEvent = new EventEmitter<UserLogin>();
  @Output() forgotPasswordEvent = new EventEmitter<void>();
  private _fb = inject(FormBuilder);
  protected formUtilities = inject(FormUtilitiesService);
  protected formLogin!: FormGroup;

  isForgotPassword = signal<boolean>(false);
  isButtonDisabled = signal<boolean>(false);
  countdown = signal<number>(0);
  timerInterval: any;

  ngOnInit(): void {
    this.loadForm();
    this.checkCooldown();
  }

  private loadForm() {
    this.formLogin = this._fb.group({
      identifier: [''],
      password: [''],
    });
  }

  protected onSubmit(): void {
    if (this.isForgotPassword()) {
      if (this.isButtonDisabled()) {
        return;
      }
      this.forgotPasswordEvent.emit(this.formLogin.value);
      this.formLogin.reset();
      this.startCooldown(60);
      return;
    }

    if (this.formLogin.valid) {
      this.loginEvent.emit(this.formLogin.value);
    }
  }

  protected resetPassword() {
    this.isForgotPassword.set(!this.isForgotPassword());
  }
  private startCooldown(seconds: number) {
    const expirationTime = Date.now() + seconds * 1000;
    sessionStorage.setItem('resetCooldown', expirationTime.toString());

    this.isButtonDisabled.set(true);
    this.countdown.set(seconds);

    this.timerInterval = setInterval(() => {
      const remainingTime = Math.floor(
        (parseInt(sessionStorage.getItem('resetCooldown') || '0') -
          Date.now()) /
          1000
      );
      if (remainingTime <= 0) {
        this.isButtonDisabled.set(false);
        this.countdown.set(0);
        clearInterval(this.timerInterval);
        sessionStorage.removeItem('resetCooldown');
      } else {
        this.countdown.set(remainingTime);
      }
    }, 1000);
  }

  private checkCooldown() {
    const cooldown = parseInt(sessionStorage.getItem('resetCooldown') || '0');
    if (cooldown > Date.now()) {
      const remainingTime = Math.floor((cooldown - Date.now()) / 1000);
      this.startCooldown(remainingTime);
    }
  }
}
