<form (ngSubmit)="onSubmit()" [formGroup]="formContact">
  <p-dialog
    [header]="dialogHeader"
    [modal]="true"
    [(visible)]="visible"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
    [style]="{ width: '50vw' }"
    (onHide)="closeDialog()"
  >
    <span class="block mb-5 p-text-secondary">
      {{
        contactToEdit
          ? "Modifica los datos del contacto"
          : "Introduce los datos del nuevo contacto"
      }}
    </span>

    <div class="formgrid grid">
      <div class="col">
        <app-input
          name="name"
          formControlName="name"
          label="Nombre*"
          [customErrorMessages]="{
            required: 'Este campo es requerido',
          }"
          type="text-required"
        />
      </div>
      <div class="col">
        <app-input
          name="phone"
          formControlName="phone"
          label="Teléfono"
          type="number"
        />
      </div>
    </div>
    <div class="formgrid grid">
      <div class="col col-6">
        <app-input
          name="email"
          formControlName="email"
          label="Email"
          type="email"
        />
      </div>
    </div>

    <div class="field gap-3">
      <label for="observation">Observaciones </label>
      <textarea
        id="observation"
        class="col-12"
        pInputTextarea
        autocomplete="off"
        formControlName="observation"
      ></textarea>
    </div>

    <small>* Campos requeridos</small>

    <ng-template pTemplate="footer">
      <p-button
        label="Cancelar"
        severity="secondary"
        (onClick)="closeDialog()"
      />
      <p-button label="Aceptar" type="submit" />
    </ng-template>
  </p-dialog>
</form>
