import { Component, Input } from '@angular/core';
import { ChipModule } from 'primeng/chip';
import {
  transformIconSeverity,
  transformServerity,
  transformStatus,
} from '../../utils/status';

@Component({
  selector: 'app-chip',
  standalone: true,
  imports: [ChipModule],
  templateUrl: './chip.component.html',
})
export class ChipComponent {
  @Input() status: any;
  @Input() iconVisible = false;
  @Input() small = false;

  getStatus() {
    return transformStatus(this.status);
  }

  getServerity() {
    return transformServerity(this.status);
  }

  getIconSeverity() {
    return transformIconSeverity(this.status);
  }
}
