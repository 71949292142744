<div class="flex align-items-center justify-content-end w-full">
  @if (!actionDisabled) {
  <p-button
    icon="pi pi-plus"
    label="Añadir"
    [text]="true"
    size="small"
    (onClick)="openModal()"
  />
  }
</div>

@if(mediaFiles.length > 0) { @for(file of mediaFiles; track $index) {
<div class="hover:surface-hover border-round-md p-2">
  <div class="flex flex-1 justify-content-between">
    <span class="flex justify-content-center align-items-center gap-2">
      @switch(file.attributes.mime) { @case('application/pdf'){
      <i class="pi pi-fw pi-file-pdf text-3xl pr-4"></i>
      } @case('video/*'){
      <i class="pi pi-fw pi-video text-3xl pr-4"></i>
      } @case('application/vnd.ms-excel'){
      <i class="pi pi-fw pi-file-excel text-3xl pr-4"></i>
      } @default{ @if(file.attributes.mime.startsWith('image')) {
      <i class="pi pi-fw pi-image text-3xl pr-4"></i>
      } @else {
      <i class="pi pi-fw pi-file text-3xl pr-4"></i>
      } } }

      <div
        class="flex flex-column gap-1 overflow-hidden w-full"
        style="word-break: break-all"
      >
        <span
          class="text-sm font-semibold text-ellipsis overflow-hidden whitespace-nowrap"
        >
          {{ file.attributes.name }}
        </span>
        <span class="text-xs">{{ file.attributes.mime }}</span>
        <span class="text-xs">{{ file.attributes.size }}</span>
      </div>
    </span>
  </div>

  @if (!actionDisabled) {
  <div class="flex gap-1 shrink-0 w-full justify-content-end">
    <p-button
      pTooltip="Eliminar"
      tooltipPosition="top"
      severity="danger"
      icon="pi pi-trash"
      [text]="true"
      (click)="deleteFile(file)"
    />

    <p-button
      pTooltip="Descargar"
      tooltipPosition="top"
      severity="info"
      icon="pi pi-download"
      [text]="true"
      (click)="downloadFile(file)"
    />
    <p-button
      pTooltip="Ver"
      tooltipPosition="top"
      icon="pi pi-eye"
      [text]="true"
      (click)="openFile(file)"
    />
  </div>
  }
</div>

} } @else {
<p-messages
  [(value)]="messages"
  escape="false"
  [closable]="false"
  [enableService]="false"
/>
}

<p-dialog
  header="Añadir archivos"
  [maximizable]="true"
  [(visible)]="visibleModal"
  (onHide)="closeModal()"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
>
  <app-upload (filesSelected)="onFilesSelected($event)" />

  <ng-template pTemplate="footer">
    <p-button label="Cancelar" severity="secondary" (onClick)="closeModal()" />
    <p-button label="Aceptar" (onClick)="acceptModal()" />
  </ng-template>
</p-dialog>
