<div class="surface-card shadow-2 border-round px-4 py-5 md:px-6 lg:px-8">
  <p-chart
    type="bar"
    height="300px"
    [data]="operationsData"
    [options]="basicOptions"
  ></p-chart>
</div>
<br />
<div class="surface-card shadow-2 border-round px-4 py-5 md:px-6 lg:px-8">
  <div class="grid">
    <div class="col-12 md:col-6">
      <p-chart
        type="line"
        height="300px"
        [data]="clientsData"
        [options]="lineOptions"
      ></p-chart>
    </div>
    <div class="col-12 md:col-6">
      <p-chart
        type="line"
        height="300px"
        [data]="agentsData"
        [options]="lineOptions"
      ></p-chart>
    </div>
  </div>
</div>
