<form (ngSubmit)="editService()" [formGroup]="serviceForm">
  <app-toolbar
    [createdAt]="
      (service().attributes ? service().attributes.createdAt : '') || ''
    "
    [updatedAt]="
      (service().attributes ? service().attributes.updatedAt : '') || ''
    "
    (onEdit)="editService()"
    (onDelete)="deleteService()"
  />

  @if (loading()) {
  <app-skelton />
  } @else {

  <div class="flex justify-content-end pb-4">
    <app-chip [status]="status()" [iconVisible]="true"></app-chip>
  </div>

  <p-card>
    <p-tabView [(activeIndex)]="activeTabIndex">
      <p-tabPanel header="Cliente" leftIcon="pi pi-briefcase">
        @if (!client() || !client().id) {
        <div class="cursor-pointer" (click)="addClient($event)">
          <p-messages
            [(value)]="messages"
            escape="false"
            [closable]="false"
            [enableService]="false"
          />
        </div>
        }
        <div class="col-12">
          <div class="flex align-items-center justify-content-between w-full">
            <div class="flex gap-1 w-full justify-content-end">
              @if (client() && client().id ) {
              <p-button
                icon="pi pi-file"
                label="Ficha"
                severity="info"
                (onClick)="viewClient()"
                [text]="true"
              />
              <p-button
                icon="pi pi-eraser"
                label="Borrar"
                (onClick)="removeClient()"
                [text]="true"
                severity="danger"
              />
              }

              <p-button
                [text]="true"
                icon="pi pi-pencil"
                label="Editar"
                (onClick)="editClient()"
              />
            </div>
          </div>

          <div class="formgrid grid">
            <div class="col-6">
              <app-input
                name="name"
                formControlName="name"
                label="Nombre*"
                type="text-required"
              />
            </div>
          </div>

          <div class="formgrid grid">
            <div class="col">
              <app-input
                name="email"
                formControlName="email"
                label="Email"
                type="email"
              />
            </div>
            <div class="col">
              <app-input
                name="phone"
                formControlName="phone"
                label="Teléfono"
                type="number"
              />
            </div>
          </div>
          <div class="formgrid grid">
            <div class="col">
              <app-input
                name="phone_optional"
                formControlName="phone_optional"
                label="Teléfono (opcional)"
                type="number"
              />
            </div>
            <div class="col">
              <app-input
                name="province"
                formControlName="province"
                label="Provincia"
              />
            </div>
          </div>
          <div class="formgrid grid">
            <div class="col">
              <app-input
                name="address"
                formControlName="address"
                label="Dirección"
              />
            </div>
            <div class="col">
              <app-input name="city" formControlName="city" label="Población" />
            </div>
          </div>
          <div class="formgrid grid">
            <div class="col">
              <app-input
                name="postal_code"
                formControlName="postal_code"
                label="Código postal"
                type="number-length"
                [minLength]="5"
                [customErrorMessages]="{
              minlength: 'Ingresa un Código postal valido',
                        }"
              />
            </div>
            <div class="col">
              <app-input
                name="nif_cif"
                formControlName="nif_cif"
                label="NIF/CIF"
              />
            </div>
          </div>
        </div>
      </p-tabPanel>

      <p-tabPanel header="Información" leftIcon="pi pi-info-circle">
        <div class="grid">
          <div class="col-12">
            @if (this.service().attributes.budget?.data?.id) {
            <div class="flex justify-content-end">
              <p-button
                icon="pi pi-fw pi-clipboard"
                label="Presupuesto"
                severity="info"
                (onClick)="viewBudget()"
                [text]="true"
              />
            </div>
            }

            <div class="col-6 gap-2 field">
              <span class="flex">
                <i class="pi pi-fw pi-calendar pr-4"></i>
                <label for="startAt" class="font-semibold"
                  >Fecha de inicio</label
                >
              </span>
              <p-calendar
                id="startAt"
                formControlName="startAt"
                [showTime]="true"
                appendTo="body"
                [showIcon]="true"
                [showTime]="true"
                [showOnFocus]="false"
                placeholder="dd/mm/YYYY"
                dateFormat="dd/mm/yy"
              />
            </div>

            <div class="col gap-2 field">
              <span class="flex">
                <i class="pi pi-fw pi-pen-to-square pr-4"></i>
                <label for="import" class="font-semibold">Descripción</label>
              </span>
              <textarea
                id="description"
                pInputTextarea
                rows="6"
                class="w-full"
                autocomplete="off"
                formControlName="description"
              ></textarea>
            </div>

            @if (isAdmin()) {
            <div class="col gap-2 field border-round">
              <div class="flex justify-content-between">
                <span class="flex items-center align-content-center">
                  <i class="pi pi-fw pi-euro pr-4"></i>
                  <label for="import" class="font-semibold">Importe</label>
                </span>
              </div>
              <p-inputNumber
                id="import"
                autoWidth="false"
                formControlName="import"
                mode="currency"
                currency="EUR"
                locale="es-ES"
                [maxFractionDigits]="5"
              />
            </div>
            }
          </div>
        </div>
      </p-tabPanel>

      <p-tabPanel header="Empleados" leftIcon="pi pi-users">
        <app-employee-list
          [employees]="employees()"
          (employeeSelected)="onEmployeeSelected($event)"
        />
      </p-tabPanel>

      <p-tabPanel header="Archivos" leftIcon="pi pi-paperclip">
        <app-media-viewer
          [mediaFiles]="mediaFiles()"
          (onUpload)="onUpload($event)"
          (onDelete)="onDelete($event)"
        />
      </p-tabPanel>

      <p-tabPanel header="Contactos" leftIcon="pi pi-address-book">
        <app-contact-people
          [contacts]="contacts()"
          [idService]="idService()"
          (contactAdded)="onContactAdded($event)"
          (contactUpdated)="onContactUpdated($event)"
          (contactDeleted)="onContactDeleted($event)"
        />
      </p-tabPanel>

      <p-tabPanel header="Notas" leftIcon="pi pi-file-edit">
        <app-notes
          [notes]="notes()"
          [idService]="idService()"
          (onAddNote)="onAddNote($event)"
          (onEditNote)="onEditNote($event)"
          (onDeleteNote)="onDeleteNote($event)"
        />
      </p-tabPanel>

      <p-tabPanel header="Historial" leftIcon="pi pi-history">
        <app-history [statusHistories]="statusHistories()" />
      </p-tabPanel>
    </p-tabView>
  </p-card>
  }
</form>

<p-dialog
  header="Selecciona cliente"
  [modal]="true"
  [maximizable]="true"
  [(visible)]="visible"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [style]="{ width: '30vw' }"
  (onHide)="closeDialog()"
>
  <span class="block mb-5 p-text-secondary"
    >Seleciona el cliente al que quieres asignar el presupuesto</span
  >
  <app-client-selector
    (clientSelected)="onClientSelected($event)"
    [dialogVisible]="visible"
  />

  <ng-template pTemplate="footer">
    <p-button
      label="Cancelar"
      severity="secondary"
      icon="pi pi-times"
      (onClick)="closeDialog()"
    />
    <p-button
      label="Aceptar"
      icon="pi pi-check"
      (onClick)="updateClientService()"
    />
  </ng-template>
</p-dialog>
