import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { BASEURL } from '../constant/url';
import { User, UserCreate, Users } from '../interfaces/user';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private readonly _http = inject(HttpClient);

  fetchUsers(page: number, pageSize?: number): Observable<Users> {
    return this._http
      .get<Users>(
        `${BASEURL}users?populate=categories&pagination[page]=${page}&pagination[pageSize]=${
          pageSize || 10
        }`
      )
      .pipe(map((response: Users) => response));
  }

  getUser(id: number): Observable<User> {
    return this._http
      .get<User>(BASEURL + 'users/' + id + '?populate=categories')
      .pipe(map((response: User) => response));
  }

  addUser(data: UserCreate): Observable<User> {
    return this._http.post<User>(BASEURL + 'invite-user', data);
  }

  deleteUser(id: number): Observable<void> {
    return this._http.delete<void>(BASEURL + 'users/' + id);
  }

  updateUser(id: number, data: User): Observable<User> {
    return this._http.put<User>(BASEURL + 'users/' + id, data);
  }

  activationUser(token: string, password: string): Observable<string> {
    const data = {
      token,
      password,
    };
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post<string>(BASEURL + 'complete-registration', data, {
      headers,
    });
  }

  resetPassword(
    password: string,
    passwordConfirmation: string,
    code: string
  ): Observable<string> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    const data = {
      password: password,
      passwordConfirmation: passwordConfirmation,
      code: code,
    };

    return this._http.post<string>(BASEURL + 'auth/reset-password', data, {
      headers,
    });
  }
}
