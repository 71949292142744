import {
  Component,
  inject,
  OnInit,
  signal,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { ChartModule } from 'primeng/chart';
import { StatsService } from '../../services/stats.service';
import { Operations } from '../../interfaces/operation';
import { MONTHS } from '../../constant/date';

@Component({
  selector: 'app-chart',
  standalone: true,
  imports: [ChartModule],
  templateUrl: './chart.component.html',
})
export class ChartComponent implements OnInit, OnDestroy, AfterViewInit {
  private readonly _statsService = inject(StatsService);
  statsOperations = signal<Operations>({} as Operations);

  operationsData: any;
  clientsData: any;
  agentsData: any;
  basicOptions: any;
  lineOptions: any;

  private observer!: MutationObserver;

  ngOnInit(): void {
    setTimeout(() => {
      this.loadChartData();
    }, 100);
  }

  ngAfterViewInit(): void {
    // Detectar cambios en el tema
    this.observeThemeChanges();
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  private loadChartData(): void {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color').trim();
    const textColorSecondary = documentStyle
      .getPropertyValue('--text-color-secondary')
      .trim();

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const gradientClient = ctx!.createLinearGradient(0, 0, 0, 400);
    gradientClient.addColorStop(0, 'rgba(163, 216, 232, 1)');
    gradientClient.addColorStop(1, 'rgba(195, 232, 242, 0)');

    const gradientAgents = ctx!.createLinearGradient(0, 0, 0, 400);
    gradientAgents.addColorStop(0, 'rgba(171, 2, 243, 1)');
    gradientAgents.addColorStop(1, 'rgba(228, 208, 251, 0)');

    this._statsService.fetchOperations().subscribe((data) => {
      this.operationsData = {
        labels: MONTHS,
        datasets: [
          {
            label: 'Servicios',
            data: data.map((item: any) => item.services),
            fill: true,
            tension: 0.5,
            backgroundColor: documentStyle.getPropertyValue('--blue-500'),
            borderColor: documentStyle.getPropertyValue('--blue-500'),
            hoverBackgroundColor: documentStyle.getPropertyValue('--blue-600'),
            hoverBorderColor: documentStyle.getPropertyValue('--blue-600'),
          },
          {
            label: 'Presupuestos',
            data: data.map((item: any) => item.budgets),
            fill: false,
            tension: 0.5,
            backgroundColor: documentStyle.getPropertyValue('--orange-500'),
            borderColor: documentStyle.getPropertyValue('--orange-500'),
            hoverBackgroundColor:
              documentStyle.getPropertyValue('--orange-600'),
            hoverBorderColor: documentStyle.getPropertyValue('--orange-600'),
          },
        ],
      };

      this.clientsData = {
        labels: MONTHS,
        datasets: [
          {
            label: 'Clientes',
            data: data.map((item: any) => item.clients),
            fill: true,
            tension: 0.5,
            backgroundColor: gradientClient,
            borderColor: documentStyle.getPropertyValue('--cyan-500'),
            hoverBackgroundColor: documentStyle.getPropertyValue('--cyan-600'),
            hoverBorderColor: documentStyle.getPropertyValue('--cyan-600'),
          },
        ],
      };

      this.agentsData = {
        labels: MONTHS,
        datasets: [
          {
            label: 'Agentes',
            data: data.map((item: any) => item.agents),
            fill: true,
            tension: 0.5,
            backgroundColor: gradientAgents,
            borderColor: documentStyle.getPropertyValue('--purple-500'),
            hoverBackgroundColor:
              documentStyle.getPropertyValue('--purple-600'),
            hoverBorderColor: documentStyle.getPropertyValue('--purple-600'),
          },
        ],
      };
    });

    this.lineOptions = {
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            display: false,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            display: false,
          },
        },
      },
    };

    this.basicOptions = {
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            display: false,
          },
        },
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            display: false,
          },
        },
      },
    };
  }

  private observeThemeChanges(): void {
    this.observer = new MutationObserver(() => {
      this.loadChartData();
    });

    this.observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['class'],
    });
  }
}
