import { Component, inject, OnInit, signal } from '@angular/core';
import { RegisterFormComponent } from '../../components/register-form/register-form.component';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../../services/user.service';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [RegisterFormComponent, ToastModule],
  templateUrl: './reset-password.component.html',
  providers: [MessageService],
})
export class ResetPasswordComponent implements OnInit {
  private readonly _token = signal<string>('');
  private readonly _router = inject(Router);
  private readonly _activeRoute = inject(ActivatedRoute);
  private readonly _userService = inject(UsersService);
  private readonly _messageService = inject(MessageService);
  protected loading: boolean = false;
  protected isActivate = signal<boolean>(false);
  protected message = signal<string>('');

  ngOnInit(): void {
    this._activeRoute.queryParams.subscribe((params) => {
      const token = params['code'];
      if (token) {
        this._token.set(token);
      }
    });
  }

  resetPassword(userResetPassword: any) {
    this.loading = true;
    console.log('token', this._token());
    console.log(userResetPassword.password);
    console.log(userResetPassword.confirmPassword);
    this._userService

      .resetPassword(
        userResetPassword.password,
        userResetPassword.confirmPassword,
        this._token()
      )
      .subscribe({
        next: (value) => {
          this._messageService.add({
            severity: 'success',
            summary: 'Info',
            detail: 'Contraseña restablecida con éxito.',
          });
          this.loading = false;
          setTimeout(() => {
            this._router.navigate(['/login']);
          }, 2000);
        },
        error: (error) => {
          this._messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: error?.error?.error?.message || 'Ha ocurrido un error',
          });
          this.loading = false;
        },
      });
  }
}
