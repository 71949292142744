<form (ngSubmit)="onSubmit()" [formGroup]="formEmployee">
  <p-dialog
    header="Añadir Empleado"
    [modal]="true"
    [(visible)]="visible"
    (onHide)="closeDialog()"
    [maximizable]="true"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
    [style]="{ width: '50vw' }"
  >
    <span class="block mb-5 p-text-secondary">
      Introduce los datos del nuevo empleado
    </span>

    <div class="field">
      <label for="categories" class="font-semibold">Categorías</label>
      <p-multiSelect
        [options]="categories()"
        formControlName="categories"
        optionLabel="attributes.name"
        optionValue="id"
        placeholder="Seleccione Categorías"
        appendTo="body"
      />
    </div>

    <div class="formgrid grid gap-3">
      <div class="col">
        <app-input
          name="username"
          formControlName="username"
          label="Nombre*"
          type="text-required"
        />
      </div>
      <div class="col">
        <app-input name="surname" formControlName="surname" label="Apellidos" />
      </div>
    </div>

    <div class="formgrid grid gap-3">
      <div class="col">
        <app-input
          name="phone"
          formControlName="phone"
          label="Teléfono"
          type="number"
        />
      </div>
      <div class="col">
        <app-input
          name="email"
          formControlName="email"
          label="Email*"
          type="email-required"
          [formControl]="formUtilities.getControl(formEmployee, 'email')"
          [customErrorMessages]="{
            required: 'Este campo es requerido',
            email: 'Ingresa un email valido'
          }"
        />
      </div>
    </div>

    <small>* Campos requeridos</small>

    <ng-template pTemplate="footer">
      <p-button
        label="Cancelar"
        severity="secondary"
        icon="pi pi-times"
        (onClick)="closeDialog()"
      />
      <p-button label="Aceptar" icon="pi pi-check" type="submit" />
    </ng-template>
  </p-dialog>
</form>
