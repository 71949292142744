<form (ngSubmit)="editUser()" [formGroup]="formUser">
  <div class="surface-card shadow-2 border-round pt-4 px-5">
    <span class="flex align-items-center gap-2">
      <i class="pi pi-info-circle"></i>
      <span class="font-semibold">Información</span>
    </span>
    <p-divider></p-divider>

    <div class="grid px-4 py-5 md:px-6 lg:px-8">
      <div class="col-12">
        <div class="formgrid grid">
          <div class="col-12 md:col-6">
            <app-input
              name="Nombre usuario"
              formControlName="username"
              label="Nombre usuario"
              type="text"
            />
          </div>
          <div class="col-12 md:col-6">
            <app-input
              name="email"
              formControlName="email"
              label="Email"
              type="email"
            />
          </div>
        </div>
        <div class="formgrid grid">
          <div class="col-12 md:col-6">
            <app-input
              name="phone"
              formControlName="phone"
              label="Teléfono"
              type="text"
            />
          </div>
        </div>
        <p-button
          type="submit"
          label="Guardar"
          icon="pi pi-save"
          [disabled]="formUser.invalid"
        ></p-button>
      </div>
    </div>
  </div>
</form>
