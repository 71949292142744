import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Agent, Agents } from '../interfaces/agent';
import { BASEURL } from '../constant/url';

@Injectable({
  providedIn: 'root',
})
export class AgentService {
  private readonly _http = inject(HttpClient);

  fetchAgents(page: number, pageSize?: number): Observable<Agents> {
    return this._http.get<Agents>(
      `${BASEURL}agents?pagination[page]=${page}&pagination[pageSize]=${
        pageSize || 10
      }`
    );
  }

  fetchAgent(id: number): Observable<Agent> {
    return this._http
      .get<Agent>(
        BASEURL +
          `agents/${id}?populate[contact_people]=*&populate[notes][populate]=users_permissions_user`
      )
      .pipe(map((response: Agent) => response.data));
  }

  addAgent(data: any): Observable<Agent> {
    return this._http.post<Agent>(BASEURL + 'agents', { data });
  }

  updateAgent(id: number, data: any): Observable<Agent> {
    return this._http.put<Agent>(BASEURL + `agents/${id}`, { data });
  }

  deleteAgent(id: number): Observable<Agent> {
    return this._http.delete<Agent>(BASEURL + `agents/${id}`);
  }
}
