import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BASEURL } from '../constant/url';
import { Service, Services } from '../interfaces/services';

@Injectable({
  providedIn: 'root',
})
export class ServiceService {
  private readonly _http = inject(HttpClient);

  fetchServices(page: number, pageSize?: number): Observable<Services> {
    return this._http.get<Services>(
      `${BASEURL}services?pagination[page]=${page}&pagination[pageSize]=${
        pageSize || 10
      }`
    );
  }

  fetchService(id: number): Observable<Service> {
    return this._http
      .get<Service>(
        `${BASEURL}services/${id}?populate[client]=*&populate[users_permissions_users][populate]=categories&populate[contact_people]=*&populate[budget]=*&populate[media]=*&populate[notes][populate]=users_permissions_user&populate[status]=*&populate[status_histories]=*`
      )
      .pipe(map((response: Service) => response.data));
  }

  fetchLastService(): Observable<Services> {
    return this._http.get<Services>(
      `${BASEURL}services?populate[client]=*&sort=createdAt:desc&pagination[pageSize]=5`
    );
  }

  addService(data: any): Observable<Service> {
    return this._http.post<Service>(`${BASEURL}services`, { data });
  }

  updateService(id: number, data: any): Observable<Service> {
    return this._http.put<Service>(`${BASEURL}services/${id}`, { data });
  }

  deleteService(id: number): Observable<any> {
    return this._http.delete<any>(`${BASEURL}services/${id}`);
  }
}
