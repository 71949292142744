import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InputComponent } from '../input/input.component';
import { FormUtilitiesService } from '../../services/from.utilitilies.service';
import { UserRegister } from '../../interfaces/user_register';

@Component({
  selector: 'app-register-form',
  standalone: true,
  imports: [CardModule, ButtonModule, ReactiveFormsModule, InputComponent],
  templateUrl: './register-form.component.html',
})
export class RegisterFormComponent implements OnInit {
  @Input() loading = false;
  @Input() isResetPassword = false;
  @Output() registerEvent = new EventEmitter<UserRegister>();
  @Output() resetPasswordEvent = new EventEmitter<any>();

  private _fb = inject(FormBuilder);
  protected formUtilities = inject(FormUtilitiesService);

  formRegister!: FormGroup;

  ngOnInit(): void {
    this.loadForm();
  }

  private loadForm() {
    this.formRegister = this._fb.group({
      password: [''],
      confirmPassword: [''],
    });
  }

  protected onSubmit(): void {
    if (this.formRegister.valid) {
      if (this.isResetPassword) {
        this.resetPasswordEvent.emit(this.formRegister.value);

        return;
      }

      this.registerEvent.emit(this.formRegister.value);
    }
  }
}
