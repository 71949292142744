import { Component, Input, OnInit, signal } from '@angular/core';
import { StatusHistory } from '../../interfaces/status_history';
import { TimelineModule } from 'primeng/timeline';
import { transformIconSeverity, transformStatus } from '../../utils/status';
import { CommonModule } from '@angular/common';
import { MessagesModule } from 'primeng/messages';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-history',
  standalone: true,
  imports: [TimelineModule, CommonModule, MessagesModule],
  templateUrl: './history.component.html',
})
export class HistoryComponent implements OnInit {
  @Input() statusHistories: StatusHistory[] = [];

  messages = signal<Message[]>([]);

  ngOnInit(): void {
    this.initialMessage();
  }

  private initialMessage() {
    if (!this.statusHistories || this.statusHistories.length === 0) {
      this.messages.set([
        {
          severity: 'info',
          summary: 'Sin Historial.',
        },
      ]);
    }
  }

  get processedHistories(): StatusHistory[] {
    return this.statusHistories.map((history) => ({
      ...history,
      user: history.attributes.user_updated,
      date: history.attributes.updatedAt,
      status: history.attributes.new_status,
    }));
  }

  getIconSeverity(status: string) {
    return transformIconSeverity(status);
  }

  getStatus(status: string) {
    return transformStatus(status);
  }
}
