import { Component, inject, OnInit } from '@angular/core';
import { HeaderService } from '../../services/header.service';
import { CommonModule } from '@angular/common';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { ThemeService } from '../../services/theme.service';
import { ButtonModule } from 'primeng/button';
import { Router } from '@angular/router';
import { DialogModule } from 'primeng/dialog';
import { ChagePasswordComponent } from '../../components/chage-password/chage-password.component';
import { MessageService } from 'primeng/api';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [
    CommonModule,
    InputSwitchModule,
    FormsModule,
    ButtonModule,
    DialogModule,
    ChagePasswordComponent,
  ],
  templateUrl: './settings.component.html',
})
export class SettingsComponent implements OnInit {
  private readonly _headerService = inject(HeaderService);
  private readonly _router = inject(Router);
  private readonly _messageService = inject(MessageService);
  private readonly _authService = inject(AuthService);

  readonly themeService = inject(ThemeService);

  protected visible = false;
  protected darkMode = false;

  ngOnInit(): void {
    this._headerService.setHeader('Ajustes', false);
    this.darkMode = this.themeService.isDarkMode;
  }

  goToProfile() {
    this._router.navigate(['profile']);
  }

  onSubmitChangePassword(changePasswordForm: any): void {
    if (changePasswordForm.formChangePassword.invalid) {
      changePasswordForm.formChangePassword.markAllAsTouched();
      return;
    }

    this._authService
      .chagePassword(
        changePasswordForm.formChangePassword.value.password,
        changePasswordForm.formChangePassword.value.currentPassword,
        changePasswordForm.formChangePassword.value.passwordConfirmation
      )
      .subscribe({
        next: () => {
          this.visible = false;
          this._messageService.add({
            severity: 'success',
            summary: 'Contraseña actualizada',
            detail: 'Contraseña actualizada correctamente',
          });
        },
        error: (err) => {
          console.error(err);
          this._messageService.add({
            severity: 'error',
            summary: 'Contraseña no actualizada',
            detail: err.error.error.message,
          });
        },
      });
  }
  logout() {
    this._authService.logout();
  }
}
