<div
  class="surface-card shadow-2 border-round p-4 flex flex-column cursor-pointer"
>
  <div
    class="flex justify-content-between align-items-center hover:surface-hover p-4"
  >
    <span> Modo oscuro </span>
    <p-inputSwitch
      [(ngModel)]="darkMode"
      (ngModelChange)="themeService.toggleTheme()"
    >
    </p-inputSwitch>
  </div>
  <div
    class="flex justify-content-between align-items-center hover:surface-hover p-4"
    (click)="goToProfile()"
  >
    <span> Mi perfil </span>
    <i class="pi pi-chevron-right text-primary"></i>
  </div>

  <div
    class="flex justify-content-between align-items-center hover:surface-hover p-4"
    (click)="visible = true"
  >
    <span> Cambiar contraseña </span>
    <i class="pi pi-chevron-right text-primary"></i>
  </div>

  <div
    class="flex justify-content-between align-items-center hover:surface-hover p-4"
    (click)="logout()"
  >
    <span class="text-red-500"> Cerrar sesión </span>
    <i class="pi pi-sign-out text-red-500"></i>
  </div>
</div>

<p-dialog
  header="Cambiar Contraseña"
  [modal]="true"
  [maximizable]="true"
  [(visible)]="visible"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [style]="{ width: '30vw' }"
  (onHide)="visible = false"
>
  <span class="block mb-5 p-text-secondary"
    >Introduce los datos para cambiar la contraseña</span
  >
  <app-chage-password #changePasswordForm />

  <ng-template pTemplate="footer">
    <p-button
      label="Cancelar"
      severity="secondary"
      icon="pi pi-times"
      (onClick)="visible = false"
    />
    <p-button
      label="Aceptar"
      icon="pi pi-check"
      (onClick)="onSubmitChangePassword(changePasswordForm)"
    />
  </ng-template>
</p-dialog>
