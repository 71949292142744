import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { BudgetAttributes } from '../../../interfaces/budget';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { ClientSelectorComponent } from '../../../components/client-selector/client-selector.component';
import { Client } from '../../../interfaces/client';
import { CommonModule } from '@angular/common';
import { UploadComponent } from '../../../components/upload/upload.component';
import { CalendarModule } from 'primeng/calendar';
import { Roles } from '../../../utils/roles';
import { InputNumberModule } from 'primeng/inputnumber';
import { AuthService } from '../../../services/auth.service';
import { InputComponent } from '../../../components/input/input.component';

export interface BudgetData {
  budgetData: BudgetAttributes;
  files: File[];
}
@Component({
  selector: 'app-add-budget',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    InputTextModule,
    InputTextareaModule,
    ReactiveFormsModule,
    ButtonModule,
    DividerModule,
    ClientSelectorComponent,
    UploadComponent,
    CalendarModule,
    InputNumberModule,
    InputComponent,
  ],
  templateUrl: './add-budget.component.html',
})
export class AddBudgetComponent implements OnInit {
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() save = new EventEmitter<BudgetData>();
  @ViewChild(UploadComponent) uploadComponent!: UploadComponent;

  protected isAdmin = signal<boolean>(false);
  protected formBudget!: FormGroup;

  private readonly _authService = inject(AuthService);
  private _selectedFiles: File[] = [];
  private _fb = inject(FormBuilder);
  private _visible = false;
  active: number = 0;

  @Input()
  set visible(value: boolean) {
    this._visible = value;
    if (this._visible) {
    }
  }
  get visible(): boolean {
    return this._visible;
  }

  ngOnInit(): void {
    this.loadForm();
    this._authService.getRoleObservable().subscribe((role) => {
      this.isAdmin.set(String(role) === Roles.ADMIN);
    });
  }

  private loadForm(): void {
    this.formBudget = this._fb.group({
      email: [''],
      name: [''],
      phone: [''],
      phone_optional: [''],
      province: [''],
      city: [''],
      address: [''],
      postal_code: [''],
      nif_cif: [''],
      description: [''],
      selectedClient: [''],
      startAt: [''],
      import: [null],
      media: [null],
    });
  }

  onClientSelected(selectedClient: Client): void {
    if (!selectedClient) {
      this.formBudget.reset();
      this.formBudget.enable({ emitEvent: false });
      return;
    } else {
      this.formBudget.disable({ emitEvent: false });
      this.formBudget.get('startAt')?.enable();
      this.formBudget.get('description')?.enable();
      this.formBudget.get('import')?.enable();
      this.updateFormValues(selectedClient);
    }
  }

  private updateFormValues(client: Client): void {
    const {
      name,
      email,
      phone,
      phone_optional,
      province,
      city,
      address,
      postal_code,
      nif_cif,
    } = client.attributes;
    this.formBudget.patchValue({
      selectedClient: client.id,
      name,
      email,
      phone,
      phone_optional,
      province,
      city,
      address,
      postal_code,
      nif_cif,
    });
  }

  onFilesSelected(files: File[]): void {
    this._selectedFiles = files;
  }

  onSubmit(): void {
    this.save.emit({
      budgetData: this.formBudget.getRawValue(),
      files: this._selectedFiles,
    });

    this.closeDialog();
  }
  closeDialog(): void {
    // this.visible = false;
    this.visibleChange.emit(false);
    this.formBudget.reset();
    this._selectedFiles = [];
    this.uploadComponent.clearFiles();
  }
}
