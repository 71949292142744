import { Component, inject, OnInit, signal } from '@angular/core';
import { TabViewModule } from 'primeng/tabview';
import { ToolbarComponent } from '../../../components/toolbar/toolbar.component';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CardModule } from 'primeng/card';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { MessagesModule } from 'primeng/messages';
import { ClientSelectorComponent } from '../../../components/client-selector/client-selector.component';
import { ContactPeopleComponent } from '../../../components/contact-people/contact-people.component';
import { InputComponent } from '../../../components/input/input.component';
import { MediaViewerComponent } from '../../../components/media-viewer/media-viewer.component';
import { NotesComponent } from '../../../components/notes/notes.component';
import { SkeltonComponent } from '../../../components/skelton/skelton.component';
import { HeaderService } from '../../../services/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { Roles } from '../../../utils/roles';
import { ServiceService } from '../../../services/service.service';
import { Service } from '../../../interfaces/services';
import { MediaFile } from '../../../interfaces/media_file';
import { Client } from '../../../interfaces/client';
import { ContactPerson } from '../../../interfaces/contact_people';
import { Note } from '../../../interfaces/note';
import {
  ConfirmationService,
  MenuItem,
  Message,
  MessageService,
} from 'primeng/api';
import { ClientService } from '../../../services/client.service';
import { SplitButtonModule } from 'primeng/splitbutton';
import {
  transformIconSeverity,
  transformServerity,
  transformStatus,
} from '../../../utils/status';
import { ItemsService } from '../items.services';
import { Subscription } from 'rxjs';
import { Users } from '../../../interfaces/user';
import { EmployeeListComponent } from '../../../components/employees-list/employee-list.component';
import { ChipComponent } from '../../../components/chip/chip.component';
import { StatusHistory } from '../../../interfaces/status_history';
import { HistoryComponent } from '../../../components/history/history.component';

@Component({
  selector: 'app-service-details',
  standalone: true,
  imports: [
    TabViewModule,
    ToolbarComponent,
    ReactiveFormsModule,
    CardModule,
    InputTextareaModule,
    ButtonModule,
    MessagesModule,
    CalendarModule,
    DialogModule,
    ClientSelectorComponent,
    MediaViewerComponent,
    InputNumberModule,
    InputComponent,
    SkeltonComponent,
    NotesComponent,
    ContactPeopleComponent,
    SplitButtonModule,
    EmployeeListComponent,
    ChipComponent,
    HistoryComponent,
  ],
  templateUrl: './service-details.component.html',
})
export class ServiceDetailsComponent implements OnInit {
  private readonly _headerService = inject(HeaderService);
  private readonly _activeRoute = inject(ActivatedRoute);
  private readonly _authService = inject(AuthService);
  private readonly _serviceService = inject(ServiceService);
  private readonly _clientService = inject(ClientService);
  private readonly _messageService = inject(MessageService);
  private readonly _confirmDialog = inject(ConfirmationService);
  // private readonly _itemsService = inject(ItemsService);
  private readonly _router = inject(Router);
  private readonly _fb = inject(FormBuilder);
  private readonly _filesIds = signal<number[]>([]);
  private readonly _clientSelected = signal<Client>({} as Client);

  protected readonly idService = signal<number>(0);
  protected messages = signal<Message[]>([]);
  protected messagesStatus = signal<Message[]>([]);
  protected isAdmin = signal<boolean>(false);
  protected service = signal<Service>({} as Service);
  protected client = signal<Client>({} as Client);
  protected employees = signal<Users>({} as Users);
  protected employeesIds = signal<number[]>([]);
  protected contacts = signal<ContactPerson[]>([]);
  protected mediaFiles = signal<MediaFile[]>([]);
  protected notes = signal<Note[]>([]);
  protected loading = signal<boolean>(false);
  protected status = signal<string>('');
  protected statusHistories = signal<StatusHistory[]>([]);

  protected serviceForm!: FormGroup;
  protected menuItems!: MenuItem[];
  private statusSubscription!: Subscription;
  protected visible = false;
  protected activeTabIndex = 0;
  ngOnInit(): void {
    this._activeRoute.params.subscribe(({ id }) => {
      this.idService.set(id);
      this._headerService.setHeader('Servicio #' + id, true);
    });
    this.loadForm();
    this.getRole();
    this.getService();

    this.initialMessage();

    // this._itemsService.getItems().subscribe((items) => {
    //   this.menuItems = items;
    // });

    // this.statusSubscription = this._itemsService.status$.subscribe(
    //   (status: Status) => {
    //     this.status.set(status);
    //   }
    // );
  }

  ngOnDestroy(): void {
    this.statusSubscription?.unsubscribe();
  }

  private getRole(): void {
    this._authService.getRoleObservable().subscribe((role) => {
      this.isAdmin.set(String(role) === Roles.ADMIN);
    });
  }

  private getService(): void {
    this.loading.set(true);
    this._serviceService.fetchService(this.idService()).subscribe({
      next: (service) => {
        const {
          media,
          client,
          users_permissions_users,
          contact_people,
          notes,
          status,
          status_histories,
        } = service.attributes;
        this.service.set(service);

        if (status) {
          this.status.set(service.attributes.status);
        }

        if (status_histories) {
          this.statusHistories.set(status_histories.data);
        }

        if (media.data) {
          const mediaFiles: MediaFile[] = media.data;
          this._filesIds.set(mediaFiles.map((file) => file.id));
          this.mediaFiles.set(mediaFiles);
        } else {
          this.mediaFiles.set([]);
        }

        if (client) {
          this.client.set(client.data);
        }

        if (users_permissions_users) {
          this.employees.set(users_permissions_users);
        }

        if (contact_people) {
          this.contacts.set(contact_people.data);
        }

        if (notes) {
          this.notes.set(notes.data.sort((a, b) => b.id - a.id));
        }

        this.updateForm();
      },
      error: () => {
        this._messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error al cargar el servicio',
        });
      },
      complete: () => {
        this.loading.set(false);
      },
    });
  }

  private initialMessage() {
    this.messages.set([
      {
        severity: 'info',
        summary: 'Cliente no registrado.',
        detail: 'Para registrar un nuevo cliente <u>pulse aquí</u>',
      },
    ]);
  }

  private loadForm() {
    this.serviceForm = this._fb.group({
      name: [''],
      email: [''],
      phone: [''],
      phone_optional: [''],
      address: [''],
      city: [''],
      province: [''],
      postal_code: [''],
      nif_cif: [''],
      startAt: [''],
      import: [0],
      observation: [''],
      description: [''],
    });
  }

  private updateForm() {
    const service = this.service()?.attributes;
    const client = this.client()?.attributes;

    const startAtDate = service?.startAt ? new Date(service.startAt ?? '') : '';

    if (client) {
      this.disabledClientForm();
    } else {
      this.serviceForm.enable();
    }

    this.serviceForm.patchValue({
      name: client?.name ?? service?.name,
      email: client?.email ?? service?.email,
      phone: client?.phone ?? service?.phone,
      phone_optional: client?.phone_optional ?? service?.phone_optional,
      province: client?.province ?? service?.province,
      city: client?.city ?? service?.city,
      address: client?.address ?? service?.address,
      postal_code: client?.postal_code ?? service?.postal_code,
      nif_cif: client?.nif_cif ?? service?.nif_cif,
      observation: service?.observation,
      description: service?.description,
      startAt: startAtDate ? startAtDate : '',
      import: service?.import,
    });
  }

  addClient(event: Event): void {
    event.preventDefault();
    if (this.serviceForm.invalid) {
      this.serviceForm.markAllAsTouched();
      return;
    }

    this._confirmDialog.confirm({
      header: 'Registro de cliente',
      icon: 'pi pi-info-circle',
      message: 'Se va ha registrar un nuevo cliente <br> ¿Deseas continuar?',
      accept: () => {
        this._clientService
          .addClient(this.serviceForm.getRawValue())
          .subscribe({
            next: (client: Client) => {
              this._serviceService
                .updateService(this.idService(), {
                  client: client.data.id,
                  ...client.data.attributes,
                })
                .subscribe({
                  next: () => {
                    this.client.set(client.data);
                    this.updateForm();
                    this._messageService.add({
                      severity: 'success',
                      summary: 'Cliente registrado',
                      detail: 'Cliente registrado correctamente',
                    });
                  },
                  error: () => {
                    this._messageService.add({
                      severity: 'error',
                      summary: 'Error',
                      detail: 'Error al registrar el cliente',
                    });
                  },
                });
            },
          });
      },
    });
  }

  updateClientService(): void {
    this.visible = false;
    const selectedClient = this._clientSelected();
    if (!selectedClient.id) {
      return;
    }

    this.client.set(selectedClient);
    this.updateForm();
  }

  //  Start Files
  onUpload(filesId: number[]) {
    if (!filesId) {
      return;
    }
    this._filesIds.update((ids) => [...ids, ...filesId]);
    this.editService(true);
  }

  onDelete(id: number) {
    if (!id) {
      return;
    }
    this._filesIds.update((ids) => ids.filter((fileId) => fileId !== id));
  }
  // End files

  editService(isMedia: boolean = false) {
    if (this.serviceForm.invalid) {
      this.serviceForm.markAllAsTouched();
      return;
    }

    let payload = {};
    if (isMedia) {
      payload = { media: this._filesIds() };
    } else {
      const {
        startAt,
        email,
        phone,
        phone_optional,
        province,
        address,
        city,
        postal_code,
        nif_cif,
        users_permissions_users,
        ...serviceData
      } = this.serviceForm.getRawValue();

      payload = {
        ...serviceData,
        client: this.client()?.id ?? null,
        startAt: startAt ? startAt : null,
        email: email ? email : null,
        phone: phone ? phone : null,
        phone_optional: phone_optional ? phone_optional : null,
        province: province ? province : null,
        address: address ? address : null,
        city: city ? city : null,
        postal_code: postal_code ? postal_code : null,
        nif_cif: nif_cif ? nif_cif : null,
        status: this.status(),
        users_permissions_users: this.employeesIds(),
      };
    }

    this._serviceService.updateService(this.idService(), payload).subscribe({
      next: () => {
        this._messageService.add({
          severity: 'success',
          summary: 'Servicio actualizado',
          detail: 'Servicio actualizado correctamente',
        });
        this.getService();
      },
      error: () => {
        this._messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error al actualizar el servicio',
        });
      },
    });
  }

  deleteService() {
    this._confirmDialog.confirm({
      header: 'Confirmación',
      message: '¿Está seguro de eliminar este servicio?',
      icon: 'pi pi-info-circle',

      accept: () => {
        this._serviceService.deleteService(this.idService()).subscribe({
          next: () => {
            this._router.navigate(['/services']);
            this._messageService.add({
              severity: 'success',
              summary: 'Servicio eliminado',
              detail: 'Servicio eliminado correctamente',
            });
          },
          error: () => {
            this._messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Error al eliminar el servicio',
            });
          },
        });
      },
    });
  }

  viewClient() {
    this._router.navigate(['/clients', this.client().id]);
  }

  viewBudget() {
    this._router.navigate([
      '/budgets',
      this.service().attributes.budget?.data.id,
    ]);
  }

  editClient() {
    this.visible = true;
  }

  removeClient() {
    this.client.set({} as Client);
    this.updateForm();
    this.serviceForm.get('name')?.reset();
    this.serviceForm.get('email')?.reset();
    this.serviceForm.get('phone')?.reset();
    this.serviceForm.get('phone_optional')?.reset();
    this.serviceForm.get('address')?.reset();
    this.serviceForm.get('city')?.reset();
    this.serviceForm.get('province')?.reset();
    this.serviceForm.get('postal_code')?.reset();
    this.serviceForm.get('nif_cif')?.reset();
  }

  closeDialog() {
    this.visible = false;
  }

  onClientSelected(selectedClient: Client) {
    if (selectedClient) {
      this._clientSelected.set(selectedClient);
      this.client.set(selectedClient);
      this.updateForm();
      this.disabledClientForm();
    }
  }

  private disabledClientForm() {
    this.serviceForm.disable({ emitEvent: false });
    this.serviceForm.get('startAt')?.enable();
    this.serviceForm.get('description')?.enable();
    this.serviceForm.get('import')?.enable();
  }

  onContactAdded(contact: any) {
    const newContact: ContactPerson = {
      id: contact.data.id,
      attributes: contact.data.attributes,
    };
    this.contacts().push(newContact);
  }

  onContactUpdated(contact: any) {
    this.contacts.update((contacts) => {
      const index = contacts.findIndex((c) => c.id === contact.data.id);
      if (index !== -1) {
        contacts[index] = {
          ...contacts[index],
          ...contact.data,
        };
      }
      return [...contacts];
    });
  }

  onContactDeleted(id: any) {
    this.contacts.update((contacts) =>
      contacts.filter((contact) => contact.id !== id)
    );
  }

  onAddNote(note: any) {
    this.notes.update((notes) =>
      [...notes, note.data].sort((a, b) => b.id - a.id)
    );
  }

  onEditNote($event: any) {
    this.notes.update((notes) => {
      const index = notes.findIndex((n) => n.id === $event.id);
      if (index !== -1) {
        notes[index] = {
          ...notes[index],
          ...$event,
        };
      }
      return [...notes];
    });
  }
  onDeleteNote($event: any) {
    this.notes.update((notes) => notes.filter((note) => note.id !== $event));
  }

  getStatus(status: string) {
    return transformStatus(status);
  }

  getServerity(status: string) {
    return transformServerity(status);
  }

  getIconSeverity(status: string) {
    return transformIconSeverity(status);
  }

  onEmployeeSelected($event: any) {
    if ($event) {
      this.employeesIds.set($event);
    }
    this.editService();
  }
}
