import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import { DividerModule } from 'primeng/divider';
import { BudgetService } from '../../services/budget.service';
import { Router } from '@angular/router';
import { Budgets } from '../../interfaces/budget';

@Component({
  selector: 'app-last-budgets',
  standalone: true,
  imports: [CommonModule, DividerModule],
  templateUrl: './last-budgets.component.html',
})
export class LastBudgetsComponent implements OnInit {
  private readonly _budgetService = inject(BudgetService);
  private readonly _router = inject(Router);
  protected budgets = signal<Budgets>({} as Budgets);
  ngOnInit(): void {
    this._budgetService.fetchLastBudget().subscribe({
      next: (budgets) => {
        this.budgets.set(budgets);
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  goToBudget(budgetId: number) {
    this._router.navigate(['budgets', budgetId]);
  }
}
