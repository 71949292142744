import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';

@Component({
  selector: 'app-upload',
  standalone: true,
  imports: [FileUploadModule],
  templateUrl: './upload.component.html',
})
export class UploadComponent {
  @Output() filesSelected = new EventEmitter<File[]>();
  @ViewChild(FileUpload) fileUpload!: FileUpload;

  protected files: File[] = [];

  onFileSelected(event: any): void {
    this.files.push(...event.files);
    this.filesSelected.emit(this.files);
  }

  onFileRemoved(event: any): void {
    const removedFile = event.file;
    this.files = this.files.filter((file) => file !== removedFile);
    this.filesSelected.emit(this.files);
  }

  clearFiles(): void {
    if (this.fileUpload) {
      this.fileUpload.clear();
    }
  }
}
