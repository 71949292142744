<form (ngSubmit)="editClient()" [formGroup]="formClient">
  <app-toolbar
    [createdAt]="
      (client().attributes ? client().attributes.createdAt : '') || ''
    "
    [updatedAt]="
      (client().attributes ? client().attributes.updatedAt : '') || ''
    "
    (onEdit)="editClient()"
    (onDelete)="deleteClient()"
  />

  <p-card>
    <p-tabView>
      <p-tabPanel header="Información" leftIcon="pi pi-info-circle">
        <div class="col-6">
          <app-input
            name="name"
            formControlName="name"
            label="Nombre*"
            [customErrorMessages]="{
              required: 'Este campo es requerido',
            }"
            type="text-required"
          />
        </div>
        <div class="grid align-content-center justify-content-center col-12">
          <div class="col-12">
            <div class="formgrid grid">
              <div class="col">
                <app-input
                  name="email"
                  formControlName="email"
                  label="Email"
                  type="email"
                />
              </div>
              <div class="col">
                <app-input
                  name="phone"
                  formControlName="phone"
                  label="Teléfono"
                  type="number"
                />
              </div>
            </div>
            <div class="formgrid grid">
              <div class="col">
                <app-input
                  name="phone_optional"
                  formControlName="phone_optional"
                  label="Teléfono (opcional)"
                  type="number"
                />
              </div>
              <div class="col">
                <app-input
                  name="province"
                  formControlName="province"
                  label="Provincia"
                />
              </div>
            </div>
            <div class="formgrid grid">
              <div class="col">
                <app-input
                  name="address"
                  formControlName="address"
                  label="Dirección"
                />
              </div>
              <div class="col">
                <app-input
                  name="city"
                  formControlName="city"
                  label="Población"
                />
              </div>
            </div>
            <div class="formgrid grid">
              <div class="col">
                <app-input
                  name="postal_code"
                  formControlName="postal_code"
                  label="Código postal"
                  type="number-length"
                  [minLength]="5"
                  [customErrorMessages]="{
    minlength: 'Ingresa un Código postal valido',
              }"
                />
              </div>
              <div class="col">
                <app-input
                  name="nif_cif"
                  formControlName="nif_cif"
                  label="NIF/CIF"
                />
              </div>
            </div>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Agentes" leftIcon="pi pi-id-card">
        <app-agent
          [agent]="agent()"
          (agentSelected)="onAgentSelected($event)"
          (agentRemoved)="onAgentRemoved()"
        />
      </p-tabPanel>

      <p-tabPanel header="Contactos" leftIcon="pi pi-address-book">
        <app-contact-people
          [contacts]="contacts()"
          [idClient]="idClient()"
          (contactAdded)="onContactAdded($event)"
          (contactUpdated)="onContactUpdated($event)"
          (contactDeleted)="onContactDeleted($event)"
        />
      </p-tabPanel>
      <p-tabPanel header="Notas" leftIcon="pi pi-file-edit">
        <app-notes
          [notes]="notes()"
          [idClient]="idClient()"
          (onAddNote)="onAddNote($event)"
          (onEditNote)="onEditNote($event)"
          (onDeleteNote)="onDeleteNote($event)"
        />
      </p-tabPanel>
    </p-tabView>
  </p-card>
</form>
