import { Component, inject, OnInit, signal } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { USERNAME } from '../../constant/storage';
import { AuthService } from '../../services/auth.service';
import { Roles } from '../../utils/roles';
import { MenuModule } from 'primeng/menu';
import { AvatarComponent } from '../avatar/avatar.component';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';

@Component({
  selector: 'app-user-tag',
  standalone: true,
  imports: [MenuModule, AvatarComponent, IconFieldModule, InputIconModule],
  templateUrl: './user-tag.component.html',
})
export class UserTagComponent implements OnInit {
  private readonly authService = inject(AuthService);
  protected items: MenuItem[] = [];

  protected role = signal<string>('');
  get username(): string {
    return localStorage.getItem(USERNAME) || '';
  }

  ngOnInit(): void {
    this.getRole();
  }

  private getRole() {
    this.authService.getRoleObservable().subscribe((role) => {
      switch (String(role)) {
        case Roles.ADMIN:
          this.role.set('Administrador');
          break;
        case Roles.WEB:
          this.role.set('Usuario');
          break;
        default:
          this.role.set('');
          break;
      }
    });
  }
}
