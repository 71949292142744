export enum Status {
  NOT_STARTED = 'NOT_STARTED',
  INITIALIZED = 'INITIALIZED',
  PAUSED = 'PAUSED',
  FINISHED = 'FINISHED',
}

export const transformStatus = (status: string) => {
  switch (status) {
    case Status.NOT_STARTED:
      return 'No iniciado';
    case Status.INITIALIZED:
      return 'Iniciado';
    case Status.PAUSED:
      return 'Pausado';
    case Status.FINISHED:
      return 'Finalizado';
    default:
      return status;
  }
};
export function transformServerity(
  status: any
): 'success' | 'warning' | 'danger' | 'contrast' | 'secondary' | undefined {
  switch (status) {
    case Status.NOT_STARTED:
      return 'danger';
    case Status.INITIALIZED:
      return 'success';
    case Status.PAUSED:
      return 'warning';
    case Status.FINISHED:
      return 'secondary';
    default:
      return 'secondary';
  }
}

export function transformIconSeverity(status: string) {
  switch (status) {
    case Status.NOT_STARTED:
      return 'pi pi-stop';
    case Status.INITIALIZED:
      return 'pi pi-play';
    case Status.PAUSED:
      return 'pi pi-pause';
    case Status.FINISHED:
      return 'pi pi-times';
    default:
      return 'pi pi-stop';
  }
}
